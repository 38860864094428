import React from 'react'
import moment from 'moment/moment'
import { useNavigate } from 'react-router-dom'
import { blogsArr } from '../components/constant/Appconstants'



function Blogs() {

  const navigator = useNavigate()

  return (
    <div className="w-full relative z-0">
        <div className='relative flex items-center justify-center bg-blogs_bg bg-top bg-cover h-[200px]  md:h-[340px]'>
          <div className='z-10'>
            <h6 className='text-[30px] text-white text-center tracking-[10px] font_thicker opacity-80'>OUR BLOGS </h6>
            <h6 className='text-center font_normal text-white opacity-100 text-[14px]'> <span onClick={()=>navigator('/')} className='relative cursor-pointer after:bg-[#fff] after:absolute after:h-[2px] after:w-0 after:bottom-0 after:left-0  text-white opacity-100  hover:after:w-full after:transition-all after:duration-300'>Home</span> / Our Blogs </h6>
            <h6 className='text-[13px] mt-1 w-[80%] mx-auto text-white text-center font_normal opacity-80'>Know more about blogs written by shwaasa guru. </h6>
          </div>
          <div className='bg-black opacity-60 absolute top-0 left-0 w-full h-[200px]  md:h-[340px]'>

         

          </div> 
        </div>

        <div className='grid md:grid-cols-3 py-10 gap-4 w-[90%] md:w-[70%] mx-auto bg-contain'>
        
        {blogsArr?.map((p,i)=>(
        <div onClick={()=>navigator(`/blogs/detail/${p?.id}`,{state:p})} data-aos="fade-up" key={i} className='group z-20 bg-[#fff] '>
          <div className='h-[200px] w-[100%] overflow-hidden object-cover'>
          <img alt="logo" src={p?.image} className='group-hover:scale-110 transition-all duration-400 overflow-hidden h-[200px] w-[100%] object-cover' />
          </div>
          <h6 className='font-[700] font_medium mt-4 text-[14px] text-left'>{p?.title}</h6>
          <h6 className='line-clamp-2 text-[13px] font_normal  text-left'>{p?.description}</h6>
          <h6 className='text-[11px] font_normal'>Blog Date : <span className='font_medium'>{moment(p?.date)?.format('LL')}</span></h6>
        </div>
        ))}

        </div>
   </div>
  )
}

export default Blogs