import { logo } from '../components/constant/Appconstants'
import React,{useState} from 'react'
import { FiMenu } from "react-icons/fi";
import { useLocation, useNavigate } from 'react-router-dom';
import { Drawer } from 'antd'
import {AiOutlineStepForward} from 'react-icons/ai';
import {IoCloseOutline} from 'react-icons/io5'
import ScrollToTop from '../pages/ScrollToTop';

import { FaFacebookF,FaTwitter,FaYoutube } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
import { TiSocialLinkedin } from "react-icons/ti";
import { IoMdCall } from "react-icons/io";

function Navbar() {

  const [step,setstep] = useState('')
  const [visible,setvisible] = useState(false)

  const {pathname} = useLocation()
  const path = pathname

  const social = [
    {icon:IoMdCall,size:14,path:'tel:+91 888-461-2008'},
    {icon:FaFacebookF,size:14,path:'https://www.facebook.com/shwaasayoga/'},
    {icon:FaTwitter,size:12,path:'https://x.com/shwaasayoga'},
    {icon:AiFillInstagram,size:14,path:'https://www.instagram.com/shwaasayoga/'},
    {icon:FaYoutube,size:12,path:'https://www.youtube.com/@ShwaasaYoga'},
    {icon:TiSocialLinkedin,size:16,path:'https://www.linkedin.com/in/shwaasa-guru-shwaasayoga-b1722234/?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app'}
  ]  

  const menu = [
     {name:'Home',path:'/'},
     {name:'About Us',path:'/about_us'},
    //  {name:'Yoga Courses',path:'/courses'},
     {name:'Blogs',path:'/blogs'},
     {name:'Events',path:'/events'},
     {name:'Gallery',path:'/gallery'},
     {name:'Media Release',path:'/media_release'},
     {name:'Contact',path:'/contact'},
  ]



  const navigator = useNavigate()
  
  async function openPath(s){
    let path = s?.path
    window.open(path,'_blank')
   }

  async function openPage(v){
    navigator(v)
    setvisible(false)
  }
  
  return (
    <div className='h-[80px] md:h-[70px] border-b -mt-1 justify-between flex items-center px-10'>
        <ScrollToTop />
        <Drawer bodyStyle={{ padding:'0px' }} open={visible} width={300} closable={false} closeIcon={false}>
          <div className=''>
            <div className='p-1 flex justify-between items-center pr-4 py-[8px] border-b border-gray-200 -ml-4 md:ml-0'>
             <img onClick={()=>navigator('/')} alt="logo" src={logo} className='w-[200px]   md:w-[140px] h-[60px] md:h-[100px]  object-contain' />
             <IoCloseOutline size={25} onClick={()=>setvisible(!visible)} />

            </div>

              {menu?.map((m,i)=>(
                <div key={i} onClick={()=>m?.path === 'na' ?  i == step ? setstep('') : setstep(i) : openPage(m?.path)} className='border-b my-3 mb-1 pb-1 px-4  flex items-center justify-between'>
                <h6 className={` text-[13px] ${m?.path === path ? 'text-[#F47928]' : 'text-slate-900'} font_normal font-[500] `}>{m?.name}</h6>
                <AiOutlineStepForward color="#F47928" />
              </div>
              ))}
          </div> 
        </Drawer>
        <div className='-ml-16 md:ml-0'>
        <img onClick={()=>navigator('/')} alt="logo" src={logo} className='w-[200px]   md:w-[140px] h-[60px] md:h-[100px]  object-contain' />
        </div>
         <div className='hidden  md:flex items-center'>
            {menu?.map((m,i)=>(
                <div className={`${pathname === m?.path ? 'border-t-[#F47928] border-t-4 ' : ' border-t-4 border-white'} `}>
                    <h6 onClick={()=>navigator(m?.path)} key={i} className={`text-[12px] ${i !== (menu?.length - 1) && 'border-r'} pt-1 border-black/10 font_medium  my-4 h-[25px] cursor-pointer px-4 font-[700] relative after:bg-[#F47928] after:absolute after:h-[3px] after:w-0 after:-top-5 after:left-0 hover:after:w-full after:transition-all after:duration-300`}>{m?.name}</h6>
                </div>
            ))}
         </div>

         <div className='hidden md:flex border-l items-center'>
          {social?.map((s,i)=>(
             <s.icon onClick={()=>openPath(s)} key={i} size={s?.size}  className='mx-1 border-r w-[25px] cursor-pointer hover:text-[#F47928]  flex items-center justify-center'/>
          ))}
         </div>

         <FiMenu onClick={()=>setvisible(true)} size={30} className='bg-slate-100 block md:hidden p-2 rounded -mr-4' />
       

    </div>
  )
}

export default Navbar