import React,{useState} from 'react';
import { GiSunflower } from "react-icons/gi";
import about_right from '../components/images/about_right.jpg'
import { IoIosArrowBack,IoIosArrowForward } from 'react-icons/io';
import Slider from "react-slick";
import { FaQuoteLeft,FaQuoteRight } from "react-icons/fa6";
import DOMPurify from "dompurify";


import istalinga_section from '../components/images/istalinga_section.png'

import breakSection from '../components/images/break.jpeg'


//design 
import flower_top from '../components/images/designs/flower_top.png'
import flower_down from '../components/images/designs/flower_down.png'
import boochakra from '../components/images/designs/boochakra.svg'

//banner 
import banner0 from '../components/images/banner/banner0.jpg'
import banner1 from '../components/images/banner/banner1.jpg'
import banner2 from '../components/images/banner/banner2.jpg'
import banner3 from '../components/images/banner/banner3.jpg'
import banner4 from '../components/images/banner/banner4.jpg'

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { useNavigate } from 'react-router-dom';

import shwaasa from '../components/images/shwaasa.jpg' 
import extraordinary from '../components/images/extrodinary.jpg' 
import humanity from '../components/images/humanity.jpg' 

import {BsArrowLeftCircle,BsArrowRightCircle} from 'react-icons/bs'

// vision mission values images 
import vission from '../components/images/vision_mission_values/vision.jpg'
import mission from '../components/images/vision_mission_values/mission.jpg'
import values from '../components/images/vision_mission_values/values.jpg'
import { blogsArr, galleryArr, testimonialArr } from '../components/constant/Appconstants';

export default function Home() {

  const [index,setindex] = useState(0)

  const navigator = useNavigate()

  
  const banner = [
      {
        id:0,
        img:banner0,
        title:'The Making of the Himalayan Master <span style="color:#F47928">SWAMI VACHANANANDA</span>',
        description:'',
        path:''
       },
       {
        id:1,
        img:banner1,
        title:'Master of extraordinary <span style="color:#F47928">RESTRAINT</span> and <span style="color:#F47928">DISCIPLINE</span>',
        description:'',
        path:''
       },
       {
        id:2,
        img:banner2,
        title:'The POWER of his breath tamed the <span style="color:#F47928">DEATHLY COLD</span>',
        description:'',
        path:''
      },
      // {
      //   id:3,
      //   img:banner3,
      //   title:'',
      //   description:'',
      //   path:''
      // },
  ]  



  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
    <BsArrowLeftCircle 
    className={`${className} -mt-0  md:-mt-2 `}
    style={{ ...style, display: "block",color:'#ddd',}}
    onClick={onClick} />
    );
  }
    
  function SamplePrevArrow(props) {
      const { className, style, onClick } = props;
      return (
        <BsArrowRightCircle 
            className={`${className} -mt-0 md:-mt-2 `}
            style={{ ...style, display: "block",color:'#ddd',}}
            onClick={onClick} />
      );
  }

  const settings1 = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 100,
    slidesToShow: 6,
    slidesToScroll: 6,
    initialSlide: 0,
    centerMode: true,
    cssEase: "linear",
    autoplaySpeed: 2000,
    centerPadding: '0px',
    autoPlay:true,
    prevArrow:<SampleNextArrow />,
    nextArrow:<SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 6,
          autoPlay:true
         
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          autoPlay:true

        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          autoPlay:true
        }
      }
    ]
  };

  const settings2 = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 100,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    centerMode: true,
    cssEase: "linear",
    autoplaySpeed: 2000,
    centerPadding: '0px',
    autoPlay:true,
    prevArrow:<SampleNextArrow />,
    nextArrow:<SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoPlay:true
         
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          autoPlay:true

        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          autoPlay:true
        }
      }
    ]
  };

  return (
     <div className="w-full relative z-0">

          {/* carousel section */}
          <div className='relative'>
                <Carousel 
                  centerMode={true} 
                  autoPlay={true} 
                  infiniteLoop={true}
                  showStatus={false}
                  showThumbs={false}
                  showArrows={true}
                  swipeable={true}
                  autoFocus={true}
                  interval={5000}
                  centerSlidePercentage={100}
                  showIndicators={false}
                  className='relative -z-0'
                  onChange={(e)=>{
                    setindex(e)
                  }}
                  
                  renderArrowPrev={(clickHandler, hasPrev) => {
                    return (
                      <div
                        className={`${hasPrev ? "opacity-100" : "opacity-100"} absolute backdrop-filter  bg-white/60 backdrop-blur-sm left-[20px] rounded bottom-[40%] md:bottom-[40%] z-100   flex justify-center items-center p-2  cursor-pointer z-20`}
                        onClick={clickHandler}
                      >
                        <IoIosArrowBack size={14} className="text-black" />
                      </div>
                    );
                  }}
                  renderArrowNext={(clickHandler, hasPrev) => {
                    return (
                      <div
                        className={`${hasPrev ? "opacity-100" : "opacity-100"} absolute backdrop-filter  bg-white/60 backdrop-blur-sm  right-[20px] rounded md:right-4 bottom-[40%] md:bottom-[40%]   flex justify-center items-center p-2  cursor-pointer z-20`}
                        onClick={clickHandler}
                      >
                        <IoIosArrowForward size={14} className="text-black" />
                      </div>
                    );
                  }}
                
                >
                    {banner?.map((c,i)=>(
                        <div className='text-center relative flex justify-end w-[100%]  md:h-[91vh]  overflow-hidden z-1  mx-auto focus:outline-none focus:ring-0'>
                            <img src={c?.img}  className='h-[35vh] md:h-[90vh] object-cover w-screen mx-auto '/>
                            <div className="h-[35vh] md:h-[90vh] bg-black/50 absolute top-0 w-screen"></div>
            
                            
                            <div className='absolute left-[10%] md:left-[10%] top-[60%] md:top-[45%] md:top-[45%] w-[70%] md:w-[25%]'>
                              <h6 dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(c?.title)}} className='font_medium text-white text-left text-[20px] md:text-[28px]' ></h6>
                             
                            </div>
                        </div>    
                    ))}
                </Carousel>
          </div>

          {/* about section */}
         <div className="w-[90%] my-10 md:flex mx-auto py-10">
          <div className='relative md:w-[50%] flex items-center justify-center'>
          <img data-aos="fade-right" alt="logo" src={about_right} className='h-[400px] z-20 object-contain' />
          <img alt="chakra" src={boochakra} className='absolute opacity-20 top-[-10%] right-[-20px] md:right-[20px] -z-10 h-[250px] object-contain' />
          <img alt="chakra" src={boochakra} className='absolute opacity-20 bottom-[-10%] left-[-20px] md:left-[20px] -z-10 h-[250px] object-contain' />

          </div>  
          <div className="mt-10 md:mt-0 md:w-[50%]">
                <h6 data-aos="fade-up" className="text-[20px] font_normal font-[800]">About </h6>
                
                <div className="md:w-[80%] mt-2">
                <h6 data-aos="fade-up" className="text-[14px] font_normal mt-2">A divine ambiance is what the Yoga Ashram in India creates to make impactful changes in everyone`&apos;`s life. Shwaasa, a spiritually-focused organization, encourages yogis to immerse themselves into a deep understanding of the ancient art. </h6>  
                <h6 data-aos="fade-up" className="text-[14px] font_normal mt-2">Built upon the foundations of authenticity, trust and devotion towards the ancient science, the Shwaasa Yoga Ashram was founded in 2012 by Swami Vachananand Ji in Bangalore, Karnataka.</h6>
                <h6 data-aos="fade-up" data-aos-delay="300" onClick={()=>navigator('/about_us')} className="border font_normal border-slate-400 p-1.5 text-[11px] cursor-pointer transition-all duration-300 hover:scale-105 hover:border-[#F47928] hover:bg-[#F47928] hover:text-white  w-[90px] text-center mt-5">Know More</h6>

          </div>
          </div>


         </div>
        
         <div className='w-[90%] md:w-[80%] mx-auto mb-10'>
         <iframe width="100%" className='h-[300px] md:h-[600px]' src="https://www.youtube.com/embed/kBu0x10PLck?si=q15lB5R0uB2iCITz&amp;controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
         </div>
          {/* posts section */}
         <div className="text-center  bg-[#c1a084] p-10 relative">

          <img src={flower_top} className='w-[30%]  z-10 absolute top-0 left-0 text-red-300' />
          <img src={flower_down} className='w-[25%] z-10  absolute bottom-0 right-0 text-red-300'  />
               <h6 className="font-[800] font_medium text-white text-[20px]">Latest Posts</h6>
               
               <div className='flex items-center justify-center mt-1'>
               <GiSunflower data-aos="fade-right" className='text-white' />

               <h6 data-aos="fade-up" className='w-[40px] h-[2px] bg-[#fff] text-center'></h6>
               <GiSunflower data-aos="fade-left" className='text-white' />
               </div>

               <h6 data-aos="fade-up" className="font-[400] font_normal text-white text-[13px]">Read our latest blogs posts</h6>


               <div className='grid md:grid-cols-3 py-10 gap-4 md:w-[80%] mx-auto bg-contain'>
        
                {blogsArr?.slice(0,3)?.map((p,i)=>(
                <div onClick={()=>navigator(`/blogs/detail/${p?.id}`,{state:p})} data-aos="fade-up" key={i} className='group z-20 p-4 bg-[#fff] '>
                <div className='h-[200px] w-[100%] overflow-hidden object-cover'>
                <img alt="logo" src={p?.image} className='group-hover:scale-110 transition-all duration-400 overflow-hidden h-[200px] w-[100%] object-cover' />
                </div>
                <h6 className='font-[700] font_medium mt-4 text-[15px] text-left'>{p?.title}</h6>
                <h6 className='text-[14px] font_normal mt-1 text-left'>{p?.description}</h6>

                <h6 className="border font_normal border-slate-400 p-1.5 text-[11px] cursor-pointer transition-all duration-300 hover:scale-105 hover:border-[#F47928] hover:bg-[#F47928] hover:text-white  w-[90px] text-center mt-5">Read More</h6>

                </div>
                ))}

               </div>
         </div>

          {/* vision section */}
          {/* <div className='relative bg-[#fffaeb]'>
       
         <div className='text-center py-16 w-[80%]  md:w-[75%] mx-auto'>
          <h6 className='font-[800] font_medium text-[20px]'>Mission, Vision and Values of Shwaasa</h6>
               
            <div  className='flex items-center justify-center mt-1'>
            <GiSunflower data-aos="fade-right" className='text-[#F47928]' />

            <h6 data-aos="fade-up" className='w-[40px] h-[2px] bg-[#F47928] text-center'></h6>
            <GiSunflower data-aos="fade-left" className='text-[#F47928]' />
            </div>

            <h6 data-aos="fade-up" className="font-[400] text-black font_normal text-[13px]">What we are looking for</h6>


           <div className='grid grid-cols-1 mt-10 md:grid-cols-3 gap-10 '>
            <div data-aos="fade-right" className='group relative overflow-hidden shadow-2xl  bg-[#f9f7f1]'>
              <div className='head-polygon w-max p-1 z-10 absolute right-0 top-0 px-4 bg-[#01334a] h-[30px]'>
                <h6 className='text-[12px] text-white font_medium font-[700]'>Vision</h6>
              </div>
              <div className='-z-10 h-[200px] overflow-hidden'>
              <img src={vission} className='group-hover:scale-105 w-screen duration-300 h-[200px] object-cover object-center' />
              </div>
              <div className='z-30 bg-[#f9f7f1] -mt-4'>
              <div className='z-10 clip-polygon-rl overflow-hidden -mt-[39px] bg-[#f9f7f1] h-[40px]'>
              </div>
              <h6 className="z-20 text-[14px] font_normal px-4 py-2 text-left">Shwaasa Yoga Ashram or ‘the doors of heaven’ is a recognized host which encounters the abilities and deep possibilities of a nonchalant yogi. The ambitious goals of our Indian Yoga Guru: Swami Vachananand Ji is not restricted to any geographical era or an ashram, his vision is to touch the lives of thousands of people just by using the power of ahimsa, co-existence and gratitude.</h6>
              </div>
            </div> 

            <div data-aos="fade-up" className='group relative shadow-2xl bg-[#f9f7f1]'>
              <div className='head-polygon w-max p-1 z-10 absolute right-0 top-0 px-4 bg-[#01334a] h-[30px]'>
                <h6 className='text-[12px] font_medium text-white font-[700]'>Mission</h6>
              </div>
              <div className='-z-10 h-[200px] overflow-hidden'>
              <img src={mission} className='group-hover:scale-105 w-screen duration-300 h-[200px] object-cover object-center' />
              </div>
              <div className='z-30 bg-[#f9f7f1] -mt-4'>
              <div className='z-10 clip-polygon-lr overflow-hidden -mt-[39px] bg-[#f9f7f1] h-[40px]'>
              </div>
              <h6 className="z-20 text-[14px] font_normal px-4 py-2 text-left">With a strong mission to make this world a single-roof home of love, happiness and peace for all the growing petals, evolving beings, and endangered animals is the aim of Shwaasa Yoga School. The art of living is not just about changing thyself but also the surroundings by letting your aura flouris every day with Yoga.</h6>
              </div>
            </div> 

            <div data-aos="fade-left" className='group relative shadow-2xl bg-[#f9f7f1]'>
              <div className='head-polygon w-max p-1 z-10 absolute right-0 top-0 px-4 bg-[#01334a] h-[30px]'>
                <h6 className='text-[12px] font_medium text-white font-[700]'>Values</h6>
              </div>
              <div className='-z-10 h-[200px] overflow-hidden'>
              <img src={values} className='group-hover:scale-105 w-screen duration-300 h-[200px] object-cover object-center' />
              </div>
              <div className='z-30 bg-[#f9f7f1] -mt-4'>
              <div className='z-10 clip-polygon-rl overflow-hidden -mt-[39px] bg-[#f9f7f1] h-[40px]'>
              </div>
              <h6 className="z-20 text-[14px] px-4 py-2 font_normal text-left">On an endnote, values are the key feature of all our Yoga Retreats or Yoga Teacher Training in India programs, which makes or breaks the strongest of foundations! 200 hours Yoga Teacher Training by Shwaasa revives the original beauty of the essence of Yoga with its supreme guidance and diligence to keep alive the timeless wisdom of Vedic tradition.</h6>
              </div>
            </div>            
           </div>
       
         </div>
          </div>  */}


          {/* istalinga section */}
          <div className="bg-[#f4f4f4] p-10 ">

          <h6 className='font-[800] text-center font_medium text-[20px]'>The Isthalinga Meditation Program</h6>
               
               <div  className='flex items-center justify-center mt-1'>
               <GiSunflower data-aos="fade-right" className='text-[#F47928]' />
   
               <h6 data-aos="fade-up" className='w-[40px] h-[2px] bg-[#F47928] text-center'></h6>
               <GiSunflower data-aos="fade-left" className='text-[#F47928]' />
               </div>
               <h6 data-aos="fade-up" className="font-[400] text-center text-black font_normal text-[13px]">Let Us open Our Third Eye</h6>
            

            <div className='md:flex justify-center mt-10'>
              <div data-aos="fade-right" className='md:w-[50%] md:mr-5'>
                  <img className='w-[100%]' src={istalinga_section} />
              </div>
              
              <div className='md:ml-5 mt-10 md:mt-0 md:w-[50%]'>
                  <h6 data-aos="fade-up" className="text-[14px] md:w-[80%] text-left">When Swami Vachanananda invites you with such an enticing way, you surrender.  Ishtalinga meditation is also called Shiva Yoga. What Lingayath saint Basavanna initiated in the 12th century in Karnataka, is being successfully promoted globally by Swami Vachanananda in the <span className='font_medium'>22nd century.</span>  </h6>
                  <h6 data-aos="fade-up" className="text-[14px] md:w-[80%] text-left mt-3"><span className='font_medium'>Swami Vachanananda</span> describes it as a Hymn to the Third Eye.  “Apart from all the meditations and Yoga Asana used to activate the <span className='font_medium text-[#F47928]'>Third Eye chakra</span>, I have encouraged my students to focus on Ishtalinga meditation, which is one of the most unique forms of activating the Third Eye Chakra” Swamiji says.</h6>
                  <h6 data-aos="fade-up" className="text-[14px] md:w-[80%] text-left mt-3"> He believes in being close to Mother Earth and encourages his students to include as much nature as they can during their meditation.  It is for this reason that he introduced Ishtalingas made from special herbs. <span className='font_medium text-[#F47928]'>The Istalinga</span> is made using the <span className='font_medium'>Ayurvedic herbs</span>, Sun Stone, Moon Stone, Mercury, gerenne, ghee, mascara, ashes, tulasi, neem, camphour and Ranjaka.</h6>
                  <h6 onClick={()=>navigator('/istalinga_detail')} data-aos="fade-up" className="border font_normal border-slate-400 p-1.5 text-[11px] cursor-pointer transition-all duration-300 hover:scale-105 hover:border-[#F47928] hover:bg-[#F47928] hover:text-white  w-[90px] text-center mt-5">Read More</h6>
              </div>
            </div>
          </div>


          <div className='bg-success_bg relative z-0 md:bg-cover bg-fixed px-[5%] py-[10%]'>
          <div className='w-[100%] absolute left-0 -z-10 top-0 h-[100%] bg-black/50'></div>    
          <h6 className='text-white z-40 text-[16px] font_normal w-[60%]'>Success is a</h6>
          <h6 className='text-[30px] z-40 font_medium  text-[#F47928] w-[60%]'>PROCESS</h6>
              <div className='md:w-[65%]'>
              <h6 className='text-white z-40 text-[14px] font_normal w-[100%]'><span className='text-[30px]'>F o r </span> someone who quit an important position in a religious organization at the age of 16, and went away to the Himalayas to pursue the spiritual path and attain divine empowerment, was it a letdown to return and take over as
                  Jagadguru, the Supreme Head, of a community Matha? To confine himself this way, did it clip Swami Vachanananda's soaring wings? Well-wishers had forewarned Swamiji that leading a community to harmony was not as easy as doing yoga.</h6>
                  
              <h6 className='text-white z-40 text-[14px] font_normal w-[100%] mt-5'>Some had even wondered if Swamiji was the right choice to head the Panchamasali Jagadguru Peetha in Harihar. When Swamiji was requested to become the Jagadguru of the Panchamasali Jagadguru Peetha, he was also not very keen. At first, he too thought it would hinder his Sadhana. So, he had suggested ten other names. But the trustees of the Matha persisted.</h6>

              <h6 className='text-white z-40 text-[14px] font_normal w-[100%] mt-5'>After stalling for three long years, Swamiji acceded to their request. And he was anointed as the Jagadguru on April 20th 2018. Within a year of taking over, Swami Vachanananda worked miracles.</h6>

              </div>

          </div>

          {/* humanity section */}
          <div className="bg-white p-10 ">

            <h6 className='font-[800] text-center font_medium text-[20px]'>HUMANITY</h6>
                
                <div  className='flex items-center justify-center mt-1'>
                <GiSunflower data-aos="fade-right" className='text-[#F47928]' />

                <h6 data-aos="fade-up" className='w-[40px] h-[2px] bg-[#F47928] text-center'></h6>
                <GiSunflower data-aos="fade-left" className='text-[#F47928]' />
                </div>
                <h6 data-aos="fade-up" className="font-[400] text-center text-black font_normal text-[13px]">Is the basis for all religions</h6>
              

              <div className='md:flex justify-center mt-10'>
                <div data-aos="fade-right" className='md:w-[50%] md:mr-5'>
                    <img className='w-[100%]' src={humanity} />
                </div>
                
                <div className='md:ml-5 mt-10 md:mt-0 md:w-[50%]'>
                    <h6 data-aos="fade-up" className="text-[14px] md:w-[80%] text-left">Οnce, at a young age, Swami Vachanananda developed Christianity in a serious curiosity way.</h6>
                    <h6 data-aos="fade-up" className="text-[14px] md:w-[80%] text-left mt-3">He was 17 years old staying at the Murugha Matha, Dharwad, and a science student in his first-year Pre-University. He was very fond of his Physics teacher. Vachanananda being a good student was a favourite of the teacher. There was a great student- teacher relationship between them. During weekends the student would visit the teacher's house and help with household chores.</h6>
                    <h6 data-aos="fade-up" className="text-[14px] md:w-[80%] text-left mt-3">His Physics teacher was a practicing Christian and was frequented the Basel Mission Church in Dharwad. The young Vachanananda usually accompanied him. And being eager, he happily took part in the proceedings of the church and especially enjoyed the Sacramental bread piece given at the altar. The calm atmosphere of the church, the compassionate way in which the priest interacted with people made a deep impression on young Vachanananda In the beginning, Vachanananda blindly enjoyed his visits to the church with his favourite teacher but gradually it made a serious impact on him.</h6>
                    <h6 onClick={()=>navigator('/humanity_detail')} data-aos="fade-up" className="border font_normal border-slate-400 p-1.5 text-[11px] cursor-pointer transition-all duration-300 hover:scale-105 hover:border-[#F47928] hover:bg-[#F47928] hover:text-white  w-[90px] text-center mt-5">Read More</h6>
                </div>
              </div>
          </div>

          
          <img className='h-[100px] md:h-auto' src={breakSection} />

         {/* master of extrodinary section */}
          <div className="bg-[#f4f4f4] p-10 ">

          <h6 className='font-[800] text-center font_medium text-[20px]'>Master of extraordinary</h6>
              
              <div  className='flex items-center justify-center mt-1'>
              <GiSunflower data-aos="fade-right" className='text-[#F47928]' />

              <h6 data-aos="fade-up" className='w-[40px] h-[2px] bg-[#F47928] text-center'></h6>
              <GiSunflower data-aos="fade-left" className='text-[#F47928]' />
              </div>
              <h6 data-aos="fade-up" className="font-[400] text-center text-black font_normal text-[13px]">RESTRAINT and DISCIPLINE</h6>
            

            <div className=' md:flex justify-center mt-10'>
             
              
              <div className='md:ml-5 mt-10 md:mt-0 md:w-[50%]'>
                  <h6 data-aos="fade-up" className="text-[14px] md:w-[80%] text-left">It is true that seers and sages are more disciplined than the average person. But Swami Vachanananda's discipline is cut above the rest. His ability to restraint is extraordinary. If one notices, Swamiji's life is a living example of control and discipline, right from the saffron clothes that he wears to the chair that he sits on, to his food habits, to his everyday mannerisms, and to his style of speaking His entire life is one of self-mastery. It is second nature to Swamiji to maintain discipline in speech, lifestyle habits, and his desire to keep his environment always clean to invite positive energy. And this discipline stays with him no matter where he goes when in the Matha, or when travelling,</h6>
                  <h6 data-aos="fade-up" className="text-[14px] md:w-[80%] text-left mt-3">It was in Gacchina matha, that the seed of a disciplined life was planted in Swamiji. This seed sprouted when he lived in Rishikesh, in the Himalayas, under the tutelage of Sri Swami Veda Bharati, who was the perfect example of a disciplined yogi. His best student Swami Vachanananda imbibed these qualities of restraint and self- control. There is a regime demanded by yoga and by the life of a sage. Along with this, Swami Vachanananda has also adopted a social discipline that is required to bring about reforms and make meaningful contributions to society.</h6>
              </div>

              <div data-aos="fade-right" className='mt-10 md:mt-0 md:w-[50%] md:mr-5'>
                  <img className='w-[100%]' src={extraordinary} />
              </div>
            </div>
          </div>   

          {/* gallery section  */}
          <div className="text-center bg-indigo-50 p-10 ">
          <h6 data-aos="fade-up" className="text-[18px] font_medium font-[800] ">Our Gallery</h6>
          <div className='flex items-center justify-center mt-1'>
              <GiSunflower data-aos="fade-right" className='text-[#F47928]' />
              <h6 data-aos="fade-up" className='w-[40px] h-[2px] bg-[#F47928] text-center'></h6>
              <GiSunflower data-aos="fade-left" className='text-[#F47928]' />
            </div>

              <h6 data-aos="fade-up" className="font-[400] text-black font_normal text-[13px]">Our Latest gallery posts with high quality</h6>



          <Slider {...settings1} className='mt-5 bg-indigo-50'>
              {galleryArr?.map((g)=>(
                  <div className='w-[95%]'>
                  <img className='mr-5 bg-indigo-50 w-[95%] mx-[2%]  h-[140px] object-cover' src={g?.src} />
                  </div>
              ))}
          </Slider> 


          <div>
          <h6 onClick={()=>navigator('/gallery')} data-aos="fade-up" className="border mx-auto mt-4 font_normal border-slate-400 p-1.5 text-[11px] cursor-pointer transition-all duration-300 hover:scale-105 hover:border-[#F47928] hover:bg-[#F47928] hover:text-white  w-[90px] text-center mt-12">View More</h6>
          </div>

          </div> 

          {/* testimonial slider  */}
          <div className="text-center bg-circle_bg bg-contain bg-center bg-no-repeat   p-10 ">
          <h6  data-aos="fade-up" className="text-[18px] font_medium font-[800] mt-10">Testimonials</h6>

          <div className='flex items-center justify-center mt-1'>
              <GiSunflower data-aos="fade-right" className='text-[#F47928]' />
              <h6 data-aos="fade-up" className='w-[40px] h-[2px] bg-[#F47928] text-center'></h6>
              <GiSunflower data-aos="fade-left" className='text-[#F47928]' />
              </div>

              <h6 data-aos="fade-up" className="font-[400] text-black font_normal text-[13px]">Here what our Believers says</h6>


              <Slider {...settings2} className='mt-5 py-5  md:mx-[20%]'>
                {testimonialArr?.map((t)=>(
                <div>
                  <h6 className="font-[400] text-black font_normal text-[12px]">{t?.written_by} - {t?.date}</h6>
                  <h6 className="font-[400] text-black font_normal text-[17px] my-5">{t?.title}</h6>
                
                  <div className='relative w-[95%] md:w-[70%] mx-auto'>
                      <FaQuoteLeft size={20} className='absolute text-slate-600 opacity-50 top-[-25px] left-0 ' />
                      <h6 className='text-center font_normal text-[14px] px-5 mt-5 mb-5 mb-16 '>{t?.description}</h6>
                      <FaQuoteRight size={20} className='absolute text-slate-600 opacity-50 bottom-[-25px] right-0 '/>
                  </div> 
                </div>  
                ))}
              </Slider> 


          </div> 
          
          {/* transforming request  */}
          <div className='bg-[#fffaeb] bg-tree_bg bg-contain bg-no-repeat bg-opacity-60 py-10'>
            <div className='z-20 md:w-[70%] md:ml-[30%]'>
              <img data-aos="fade-up" className='w-[80%] md:w-[30%] md:h-[250px] object-contain mx-auto my-10' src={shwaasa} />
              <div>
                <h6 data-aos="fade-up" data-aos-delay="100" className='text-center font_normal text-slate-600 text-[20px]'>Empowering with <span className='font_medium font-[800]'>YOGA</span></h6>
                <h6 data-aos-delay="200" className='text-center font_normal text-slate-600 text-[24px]'><span className='font_medium font-[800]'>transforming</span> the lives of people</h6>
                
                <div className='relative w-[95%] md:w-[70%] mx-auto'>
                <FaQuoteLeft size={30} className='absolute text-slate-600 opacity-50 top-[-15px] left-0 ' />
                <h6 data-aos-delay="300" className='text-center font_normal text-[14px] px-5 mt-5 mb-5 mb-16 '>Swami Vachanananda declared on several occasions that "to see a smile on every face is my mission". A healthy mind in a healthy body is the means to making that mission a reality. The words of Swami Rama resonated in Swamiji's mind - Those who strive to attain personal enlightenment and help others light their lamps are the true leaders of the human race.</h6>
                <FaQuoteRight size={30} className='absolute text-slate-600 opacity-50 bottom-[-15px] right-0 '/>
                </div> 
                <h6 data-aos-delay="400" className='text-center font_normal text-[13px] font-[800]'>Shwaasa Guru - Apr 11, 2024</h6>
              </div>
          
          </div>
          </div>

     </div>    
  )
}
