import { useEffect, useState } from "react";
import './App.css'
import Navbar from "./folder/components/Navbar";
import Aos from "aos";
import "aos/dist/aos.css";
import Footer from "./folder/components/Footer";
import Home from "./folder/pages/Home";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";
import Contact from "./folder/pages/Contact";
import Events from "./folder/pages/Events";
import Courses from "./folder/pages/Courses";
import About from "./folder/pages/About";
import IstalingaDetail from "./folder/pages/detailPages/IstalingaDetail";
import Blogs from "./folder/pages/Blogs";
import MediaRelease from "./folder/pages/MediaRelease";
import GalleryData from "./folder/pages/GalleryData";
import HumanityDetail from "./folder/pages/detailPages/humanityDetail";
import Splash from "./folder/pages/Splash";
import { LuArrowUpSquare } from "react-icons/lu";
import { AiOutlineVerticalAlignTop } from "react-icons/ai";
import ScrollToTop from 'react-scroll-up';
import BlogsDetail from "./folder/pages/detailPages/BlogsDetail";
import EventsDetail from "./folder/pages/detailPages/EventsDetail";
import NotFound from "./folder/pages/NotFound";
import { Modal } from "antd";
import { eventsArr } from "./folder/components/constant/Appconstants";
import { IoIosCloseCircle } from "react-icons/io";

function App() {

  const [loader,setloader] = useState(true)
  // const [modal,setmodal] = useState(false)

  useEffect(()=>{
    Aos.init();

    setTimeout(() => {
      setloader(false)
      // setmodal(true)
    }, 3000);
  },[])

  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <div>
          <Navbar />
          <Home /> 
          <Footer/>
        </div>
      ),
    },
    {
      path: "/home",
      element: (
        <div>
          <Navbar />
          <Home /> 
          <Footer/>
        </div>
      ),
    },
    {
      path: "/about_us",
      element: (
        <div>
          <Navbar />
          <About /> 
          <Footer/>
        </div>
      ),
    },
    {
      path: "/courses",
      element: (
        <div>
          <Navbar />
          <Courses /> 
          <Footer/>
        </div>
      ),
    },
    {
      path: "/istalinga_detail",
      element: (
        <div>
          <Navbar />
          <IstalingaDetail /> 
          <Footer/>
        </div>
      ),
    },
    {
      path: "/humanity_detail",
      element: (
        <div>
          <Navbar />
          <HumanityDetail /> 
          <Footer/>
        </div>
      ),
    },
    {
      path: "/blogs",
      element: (
        <div>
          <Navbar />
          <Blogs/>
          <Footer/>
        </div>
      ),
    },
    {
      path: "/blogs/detail/:id",
      element: (
        <div>
          <Navbar />
          <BlogsDetail/>
          <Footer/>
        </div>
      ),
    },
    {
      path: "/media_release",
      element: (
        <div>
          <Navbar />
          <MediaRelease />
          <Footer/>
        </div>
      ),
    },
    {
      path: "/gallery",
      element: (
        <div>
          <Navbar />
          <GalleryData />
          <Footer/>
        </div>
      ),
    },
    {
      path: "/events/detail/:id",
      element: (
        <div>
          <Navbar />
          <EventsDetail />
          <Footer/>
        </div>
      ),
    },
    {
      path: "/events",
      element: (
        <div>
          <Navbar />
          <Events />
          <Footer/>
        </div>
      ),
    },
    {
      path: "/contact",
      element: (
        <div>
          <Navbar />
          <Contact /> 
          <Footer/>
        </div>
      ),
    },
    {
      path: "*",
      element: (
        <div>
          <Navbar />
          <NotFound /> 
          <Footer/>
        </div>
      ),
    },
  ])  

  return (
    <div className="min-w-screen w-[100vw] max-w-screen  w-screen w-[100%] overflow-x-hidden">
      
      {/* <Modal open={modal} closable={false} footer={false}>
          <div className="relative cursor-pointer">
          <IoIosCloseCircle onClick={()=>setmodal(false)}  size={30} className="absolute -right-10 z-10 -top-10 text-white/50" />
          <img src={eventsArr[0]?.img} className="h-[200px]" />
           
          <div className="mt-5">
            {(eventsArr[0]?.title !== '' && eventsArr[0]?.title !== undefined) && <h6 className='font_normal text-[18px] mb-4'><span className='font_medium'>{eventsArr[0]?.title}</span> </h6>}
            {(eventsArr[0]?.location !== '' && eventsArr[0]?.location !== undefined) && <h6 className='font_normal text-[14px] mb-1'>Location : <span className='font_medium'>{eventsArr[0]?.location}</span> </h6>}
            {(eventsArr[0]?.date !== '' && eventsArr[0]?.date !== undefined) && <h6 className='font_normal text-[14px] mb-1'>Date : <span className='font_medium'>{eventsArr[0]?.date}</span> </h6>}
          </div> 
          </div>
      </Modal> */}
    
      {loader ? 
      <Splash />

      :
      <RouterProvider router={router} />}

        <ScrollToTop showUnder={260}>
            <div className='bg-black/10  p-[10px] rounded z-50 hover:bg-black/10 hover:scale-90 duration-200'>
             <AiOutlineVerticalAlignTop size={20} className='z-30 text-[#F47928]  opacity-100' />
            </div>
        </ScrollToTop>

    </div>
  );
}

export default App;
