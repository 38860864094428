import React from 'react'
import { galleryArr } from '../components/constant/Appconstants'
import Gallery from 'react-photo-gallery'
import { GiSunflower } from "react-icons/gi";
import { useNavigate } from 'react-router-dom';

function GalleryData() {

  const navigator = useNavigate()

  return (
    <div className="w-full relative z-0">
      <div className='relative flex items-center justify-center bg-gallery_image_bg bg-center bg-cover h-[200px]  md:h-[340px]'>
        <div className='z-10'>
          <h6 className='text-[30px] text-white text-center tracking-[10px] font_thicker opacity-80'>Our Gallery </h6>
          <h6 className='text-center font_normal text-white opacity-100 text-[14px]'> <span onClick={()=>navigator('/')} className='relative cursor-pointer after:bg-[#fff] after:absolute after:h-[2px] after:w-0 after:bottom-0 after:left-0  text-white opacity-100  hover:after:w-full after:transition-all after:duration-300'>Home</span> / Our Gallery </h6>
          <h6 className='text-[13px] mt-1 w-[80%] mx-auto text-white text-center font_normal opacity-80'>Take a look of high quality images of shwaasa guru. </h6>
        </div>
        <div className='bg-black opacity-60 absolute top-0 left-0 w-full h-[200px]  md:h-[340px]'>
        </div>
      </div>

        <div className='bg-home_image_bg w-[100%] px-[10%] pb-[5%] mx-auto'>
        <div className='text-center py-16 w-[80%]  md:w-[75%] mx-auto'>
          <h6 className='font-[800] font_medium text-[20px]'>Shwaasa Guru gallery </h6>
               
            <div  className='flex items-center justify-center mt-1'>
            <GiSunflower data-aos="fade-right" className='text-[#F47928]' />

            <h6 data-aos="fade-up" className='w-[40px] h-[2px] bg-[#F47928] text-center'></h6>
            <GiSunflower data-aos="fade-left" className='text-[#F47928]' />
            </div>

            <h6 data-aos="fade-up" className="font-[400] text-black font_normal text-[13px]">What we are looking for</h6>
        </div>  

        <Gallery photos={galleryArr} />
        </div>
    </div>
  )
}

export default GalleryData