import React from 'react'
import humanity from '../../components/images/humanity.jpg' 


import section0 from '../../components/images/sections/ishtalinga/ishtalinga0.jpg'
import section1 from '../../components/images/sections/ishtalinga/ishtalinga1.jpg'
import section2 from '../../components/images/sections/ishtalinga/ishtalinga2.jpg'
import section3 from '../../components/images/sections/ishtalinga/ishtalinga3.jpg'
import section4 from '../../components/images/sections/ishtalinga/ishtalinga4.jpg'
import section5 from '../../components/images/sections/ishtalinga/ishtalinga5.jpg'
import section6 from '../../components/images/sections/ishtalinga/ishtalinga6.jpg'
import section7 from '../../components/images/sections/ishtalinga/ishtalinga7.jpg'
import section8 from '../../components/images/sections/ishtalinga/ishtalinga8.jpg'


function IstalingaDetail() {

  const sectionArr = [section0,section1,section2,section3,section4,section5,section6,section7,section8]  
  
  
  return (
    <div className="w-full  relative z-0">
        <div className='relative flex items-center justify-center bg-instalinga_bg bg-center bg-cover h-[200px]  md:h-[340px]'>
            <div className='z-10'>
                <h6 className='text-[30px] text-white text-center tracking-[10px] font_thicker opacity-80'>Istalinga Meditation Program </h6>
                <h6 className='text-center font_normal text-white opacity-100 text-[14px]'> <span onClick={()=>navigator('/')} className='relative cursor-pointer after:bg-[#fff] after:absolute after:h-[2px] after:w-0 after:bottom-0 after:left-0  text-white opacity-100  hover:after:w-full after:transition-all after:duration-300'>Home</span> / Istalinga Meditation </h6>
                <h6 className='text-[13px] mt-1 w-[80%] mx-auto text-white text-center font_normal opacity-80'>Know more about istaling meditation program by shwaasa guru. </h6>
            </div>
            <div className='bg-black opacity-60 absolute top-0 left-0 w-full h-[200px]  md:h-[340px]'>
            </div> 
        </div>

         {/* istalinga section */}
         <div className="my-14 w-[80%] mx-auto">

           
            <div className='md:flex justify-center mt-10'>
                <div data-aos="fade-right" className='md:w-[50%] md:mr-5'>
                    <img className='w-[100%]' src={humanity} />
                </div>

                <div className='md:ml-5 mt-10 md:mt-0 md:w-[50%]'>
                    <h6 data-aos="fade-up" className="text-[14px] md:w-[80%] text-left">Οnce, at a young age, Swami Vachanananda developed Christianity in a serious curiosity way.</h6>
                    <h6 data-aos="fade-up" className="text-[14px] md:w-[80%] text-left mt-3">He was 17 years old staying at the Murugha Matha, Dharwad, and a science student in his first-year Pre-University. He was very fond of his Physics teacher. Vachanananda being a good student was a favourite of the teacher. There was a great student- teacher relationship between them. During weekends the student would visit the teacher's house and help with household chores.</h6>
                    <h6 data-aos="fade-up" className="text-[14px] md:w-[80%] text-left mt-3">His Physics teacher was a practicing Christian and was frequented the Basel Mission Church in Dharwad. The young Vachanananda usually accompanied him. And being eager, he happily took part in the proceedings of the church and especially enjoyed the Sacramental bread piece given at the altar. The calm atmosphere of the church, the compassionate way in which the priest interacted with people made a deep impression on young Vachanananda In the beginning, Vachanananda blindly enjoyed his visits to the church with his favourite teacher but gradually it made a serious impact on him.</h6>
                  
                </div>
                
             
            </div>

            <div>

                  <h6 data-aos="fade-up" className="text-[14px] md:w-[80%] text-left mt-3"> He started noticing the differences between his and his teacher's religions. Once, he even contemplated on how it would be if he converted to Christianity! He asked the priest if people converted to religions other than the one they were born into. The priest, being a sensible one, answered that all religions are but one and the same.</h6>
                    <h6 data-aos="fade-up" className="text-[14px] md:w-[80%] text-left mt-3">Meanwhile, the senior Swamiji at the Murugha Matha came to know about the young boy's interest in going to church. One day he called Vachanananda and asked him about it. Vachanananda replied in all honesty that he liked the sermons of Jesus Christ. The senior Swamiji asked him to compare it with Basavanna's teachings.</h6>
                    <h6 data-aos="fade-up" className="text-[14px] md:w-[80%] text-left mt-3">Being proficient with the Vachanas of the Shiva Sharanas from a very young age, and even getting the name as a result, Vachanananda was quick to reflect on the similarities between the teachings of both Basavanna and Christ. He came to the understanding that all religions primarily preach humanity. He realised that he needed to grasp the deeper meaning of religion, which led him discover newer streams of thoughts, like for instance, Humanity over religion and positivist philosophy like Religion of Humanity.</h6>
                    <h6 data-aos="fade-up" className="text-[14px] md:w-[80%] text-left mt-3">All these pursuits, in turn, triggered his leanings towards the spiritual path and ultimately resulted in him taking to Sanyasa, with a new identity and while holding all religions equally respectful, becoming a staunch practitioner Yoga                    </h6>
                    <h6>In that sense, thanks to the temptations that played a catalytic role in pushing him towards his spiritual goal.</h6>
            
            </div>

            <h6 className='font_medium text-[18px] my-10'>Gallery</h6>

            <div className='grid grid-cols-2 md:grid-cols-5 gap-2'>
                {sectionArr?.map((s)=>(
                    <div className='w-[100%] h-[150px] md:h-[200px] cursor-pointer overflow-hidden'>
                    <img src={s}  className='hover:scale-105 duration-300 w-[100%] h-[100%] object-cover'/>
                    </div>
                ))}

            </div>
         </div> 

    </div>
  )
}

export default IstalingaDetail