import React from 'react'
import { FaFacebookF,FaTwitter,FaYoutube } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
import { logo } from '../components/constant/Appconstants'
import { useLocation, useNavigate } from 'react-router-dom';
import { TiSocialLinkedin } from "react-icons/ti";

function Footer() {

  const navigator = useNavigate()

  const {pathname} = useLocation()

  const social = [
    {icon:FaFacebookF,size:23,path:'https://www.facebook.com/shwaasayoga/'},
    {icon:FaTwitter,size:23,path:'https://x.com/shwaasayoga'},
    {icon:AiFillInstagram,size:23,path:'https://www.instagram.com/shwaasayoga/'},
    {icon:FaYoutube,size:25,path:'https://www.youtube.com/@ShwaasaYoga'},
    {icon:TiSocialLinkedin,size:27,path:'https://www.linkedin.com/in/shwaasa-guru-shwaasayoga-b1722234?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app'}
  ]  

  const menu = [
    {name:'Home',path:'/home'},
    {name:'About Us',path:'/about_us'},
    // {name:'Yoga Courses',path:'/courses'},
    {name:'Blogs',path:'/blogs'},
    {name:'Events',path:'/events'},
    {name:'Media Release',path:'/media_release'},
    {name:'Contact',path:'/contact'},
 ]

 async function openPath(s){
  let path = s?.path
  window.open(path,'_blank')
 }

  return (
    <div className='bg-[#e8eaed] w-[100%] mx-auto '>
        

        <div className='grid items-start px-10 py-16 md:w-[90%] mx-auto md:grid-cols-4 gap-5'>
             
           <div>
           <img onClick={()=>navigator('/')} alt="logo" src={logo} className='w-[200px] -ml-8 -mt-6 md:ml-0 md:w-[200px] mix-blend-multiply h-[60px] md:h-[100px]  object-contain' />
            <h6 className='text-[12px] text-[#586174] mt-2'>In the vibrant tapestry of yogic history, a true luminary emerged — Swami Vachananand Ji, the embodiment of wisdom and the driving force behind Shwaasa Guru. His journey is a heroic saga, and his teachings are a gift to the world. At the heart of Shwaasa Guru is the art of unknowingly inspiring the world, where the best yoga guru imparts invaluable techniques, principles, and the profound science of yoga.</h6>
           </div>

           

           

            <div className='md:ml-10'>
            <h6 className='uppercase text-[#464c5a] font-[700] text-[12px]'>Quick Links</h6> 
            {menu?.map((m,i)=>(
                <h6 onClick={()=>navigator(m?.path)} key={i} className={`cursor-pointer ${pathname === m?.path && 'text-orange-500 font-[700]' } text-[12px] text-[#586174] hover:text-black hover:font-[700] duration-300 mt-2`}>{m?.name}</h6>
            ))}           

            </div>

            <div className='pr-5'>

          <h6 className='uppercase text-[#464c5a] font-[700] text-[12px]'>Meet Us</h6>
          <h6 className='text-[12px] text-[#586174] mt-4'>108F, MLA Layout, RMV 2nd Stage, Boopasandra, Near Boopasandra Bus-stand, Bangalore -560094, Karnataka, India.</h6>
          <h6 className='text-[12px] text-[#586174] mt-4'>Shwaasa Yoga Center, Veerashaiva Lingayat Panchamasali Jagadguru Peetha,#311,Hanagawadi Post,Shivamogga Road,Harihara-577601.</h6>



          </div> 

          <div>
          <h6 className='uppercase text-[#464c5a] font-[700] text-[12px] '>Contact Us</h6>
          <h6 className='text-[12px] text-[#586174] cursor-pointer hover:font-[700] duration-300 hover:text-orange-500 mt-1'><a target='_blank' href='tel:+91 888-461-2008'>+91 888-461-2008 / +91 888-432-2008</a></h6>

          <div className='mt-4'>
              <h6 className='uppercase text-[#464c5a] font-[700] text-[12px] '>Email Id</h6>
              <h6 className='text-[12px] text-[#586174] cursor-pointer hover:font-[700] duration-300 hover:text-orange-500 mt-1'><a target='_blank' href='mailto:info@shwaasa.org'>info@shwaasa.org</a></h6>
          </div>

          <div className='mt-4'> 
          <h6 className='uppercase text-[#464c5a] font-[700] text-[12px] '>Follow Us</h6>
          <div className='flex items-center mt-2'> 
          {social?.map((s,i)=>(
              <div onClick={()=>openPath(s)} key={i} className='pr-4'>
              <s.icon size={s.size} className='hover:bg-orange-500 hover:text-white duration-300 cursor-pointer rounded p-[5px] duration-300  text-[#464c5a]'/>
              </div>
          ))}
          </div> 
          </div>
          </div>

        </div>


        <div className='w-[90%] py-1 mx-auto border-t border-gray-300'>
            

            <div className='mt-0'>
            <h1 className='text-[9px] text-center text-[#586174]'>© 2024 <b className='text-black'>Shwassa Guru</b>, All Right Reserved</h1>
            </div> 
        </div>


    </div>
  )
}

export default Footer