import applogo from '../images/logo.svg'

import gallery1 from '../images/gallery/gallery1.jpg'
import gallery2 from '../images/gallery/gallery2.jpg'
import gallery3 from '../images/gallery/gallery3.jpg'
import gallery4 from '../images/gallery/gallery4.jpg'
import gallery5 from '../images/gallery/gallery5.jpg'
import gallery6 from '../images/gallery/gallery6.jpg'
import gallery7 from '../images/gallery/gallery7.jpg'
import gallery8 from '../images/gallery/gallery8.jpg'
import gallery9 from '../images/gallery/gallery9.jpg'
import gallery10 from '../images/gallery/gallery10.jpg'
import gallery11 from '../images/gallery/gallery11.jpg'
import gallery12 from '../images/gallery/gallery12.jpg'
import gallery13 from '../images/gallery/gallery13.jpg'
import gallery14 from '../images/gallery/gallery14.jpg'
import gallery15 from '../images/gallery/gallery15.jpg'
import gallery16 from '../images/gallery/gallery16.jpg'
import gallery17 from '../images/gallery/gallery17.jpg'
import gallery18 from '../images/gallery/gallery18.jpg'
import gallery19 from '../images/gallery/gallery19.jpg'
import gallery20 from '../images/gallery/gallery20.jpg'
import gallery21 from '../images/gallery/gallery21.jpg'
import gallery22 from '../images/gallery/gallery22.jpg'
import gallery23 from '../images/gallery/gallery23.jpg'
import gallery24 from '../images/gallery/gallery24.jpg'
import gallery25 from '../images/gallery/gallery25.jpg'
import gallery26 from '../images/gallery/gallery26.jpg'
import gallery27 from '../images/gallery/gallery27.jpg'
import gallery28 from '../images/gallery/gallery28.jpg'
import gallery29 from '../images/gallery/gallery29.jpg'
import gallery30 from '../images/gallery/gallery30.jpg'
import gallery31 from '../images/gallery/gallery31.jpg'
import gallery32 from '../images/gallery/gallery32.jpg'
import gallery33 from '../images/gallery/gallery33.jpg'
import gallery34 from '../images/gallery/gallery34.jpg'
import gallery35 from '../images/gallery/gallery35.jpg'
import gallery36 from '../images/gallery/gallery36.jpg'
import gallery37 from '../images/gallery/gallery37.jpg'
import gallery38 from '../images/gallery/gallery38.jpg'
import gallery39 from '../images/gallery/gallery39.jpg'
import gallery40 from '../images/gallery/gallery40.jpg'
import gallery41 from '../images/gallery/gallery41.jpg'
import gallery42 from '../images/gallery/gallery42.jpg'
import gallery43 from '../images/gallery/gallery43.jpg'
import gallery44 from '../images/gallery/gallery44.jpg'
import gallery45 from '../images/gallery/gallery45.jpg'
import gallery46 from '../images/gallery/gallery46.jpg'
import gallery47 from '../images/gallery/gallery47.jpg'
import gallery48 from '../images/gallery/gallery48.jpg'
import gallery49 from '../images/gallery/gallery49.jpg'
import gallery50 from '../images/gallery/gallery50.jpg'
import gallery51 from '../images/gallery/gallery51.jpg'
import gallery52 from '../images/gallery/gallery52.jpg'
import gallery53 from '../images/gallery/gallery53.jpg'
import gallery54 from '../images/gallery/gallery54.jpg'
import gallery55 from '../images/gallery/gallery55.jpg'
import gallery56 from '../images/gallery/gallery56.jpg'
import gallery57 from '../images/gallery/gallery57.jpg'
import gallery58 from '../images/gallery/gallery58.jpg'

import media_release1 from '../images/media_release/media_release1.jpeg'
import media_release2 from '../images/media_release/media_release2.jpeg'
import media_release3 from '../images/media_release/media_release3.jpeg'
import media_release4 from '../images/media_release/media_release4.jpeg'
import media_release5 from '../images/media_release/media_release5.jpeg'
import media_release6 from '../images/media_release/media_release6.jpeg'
import media_release7 from '../images/media_release/media_release7.jpeg'
import media_release8 from '../images/media_release/media_release8.jpeg'
import media_release9 from '../images/media_release/media_release9.jpeg'
import media_release10 from '../images/media_release/media_release10.jpeg'
import media_release11 from '../images/media_release/media_release11.jpeg'
import media_release12 from '../images/media_release/media_release12.jpeg'
import media_release13 from '../images/media_release/media_release13.jpeg'
import media_release14 from '../images/media_release/media_release14.jpeg'
import media_release15 from '../images/media_release/media_release15.jpeg'
import media_release16 from '../images/media_release/media_release16.jpeg'
import media_release17 from '../images/media_release/media_release17.jpeg'
import media_release18 from '../images/media_release/media_release18.jpeg'
import media_release19 from '../images/media_release/media_release19.jpeg'
import media_release20 from '../images/media_release/media_release20.jpeg'
import media_release21 from '../images/media_release/media_release21.jpeg'
import media_release22 from '../images/media_release/media_release22.jpeg'
import media_release23 from '../images/media_release/media_release23.jpeg'
import media_release24 from '../images/media_release/media_release24.jpeg'
import media_release25 from '../images/media_release/media_release25.jpeg'
import media_release26 from '../images/media_release/media_release26.jpeg'
import media_release27 from '../images/media_release/media_release27.jpeg'
import media_release28 from '../images/media_release/media_release28.jpeg'
import media_release29 from '../images/media_release/media_release29.jpeg'
import media_release30 from '../images/media_release/media_release30.jpeg'
import media_release31 from '../images/media_release/media_release31.jpeg'
import media_release32 from '../images/media_release/media_release32.jpeg'
import media_release33 from '../images/media_release/media_release33.jpeg'
import media_release34 from '../images/media_release/media_release34.jpeg'
import media_release35 from '../images/media_release/media_release35.jpeg'
import media_release36 from '../images/media_release/media_release36.jpeg'
import media_release37 from '../images/media_release/media_release37.jpeg'
import media_release38 from '../images/media_release/media_release38.jpeg'
import media_release39 from '../images/media_release/media_release39.jpeg'
import media_release40 from '../images/media_release/media_release40.jpeg'
import media_release41 from '../images/media_release/media_release41.jpeg'
import media_release42 from '../images/media_release/media_release42.jpeg'
import media_release43 from '../images/media_release/media_release43.jpeg'
import media_release44 from '../images/media_release/media_release44.jpeg'
import media_release45 from '../images/media_release/media_release45.jpeg'

// blogs images 

import post1 from '../images/posts/post1.jpg'
import post2 from '../images/posts/post2.jpg'
import post3 from '../images/posts/post3.jpg'

// events images 
import event1 from '../images/events/event1.jpg'
import event2 from '../images/events/event2.jpg'
import event3 from '../images/events/event3.jpg'
import event4 from '../images/events/event4.jpg'
import event5 from '../images/events/event5.jpg'
import event6 from '../images/events/event6.jpg'
import event7 from '../images/events/event7.jpg'
import event8 from '../images/events/event7.jpg'
import event9 from '../images/events/event9.jpg'
import event10 from '../images/events/event10.jpg'
import event11 from '../images/events/event11.jpg'
import event12 from '../images/events/event12.jpg'
import event13 from '../images/events/event13.jpg'
import event14 from '../images/events/event14.jpg'
import event15 from '../images/events/event15.jpg'
import event16 from '../images/events/event16.jpg'
import event17 from '../images/events/event17.jpg'
import event18 from '../images/events/event18.jpg'
import event19 from '../images/events/event19.jpg'
import event20 from '../images/events/event20.png'


export const mobile = '+91 9845062227'
export const email = 'info@fidelituscorp.com'
export const address = 'Fidelitus Corp, Brigade Software Park, No. 42, Ground Floor,B Block, 27th Cross,Banashankari 2nd Stage, Bangalore – 560070, Karnataka.'
export const department = 'Fidelitus HR Lab'

export const email_regex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');

export const base_url = 'https://fidelitustech.com/api/'
export const image_base_url = 'https://fidelitustech.com/'
export const logo = applogo



export const testimonialArr = [
    {
        id:1,
        title:'A Transformational Journey with Shwassa Guru',
        description:'I embarked on my yoga journey with Shwassa Guru a year ago, and it has been a truly transformational experience. The guidance and support provided by Shwassa Guru have been unparalleled. Each session is a perfect blend of physical postures, breath control, and meditation, leading to a holistic development of mind, body, and spirit.',
        written_by:'Ananya Mehta',
        date:'June 9, 2023'
    },
    {
        id:2,
        title:"Rediscovering Inner Peace with Shwassa Guru",
        description:'Joining Shwassa Guru’s yoga classes has been a life-changing decision for me. After years of struggling with stress and anxiety, I have finally found a sanctuary of peace and balance. The techniques taught by Shwassa Guru are simple yet profoundly effective. I have learned to connect with my inner self and manage stress more efficiently.',
        written_by:'Rajesh Kumar',
        date:'Apr 5 2024'
    },
    {
        id:3,
        title:'Healing and Growth with Shwassa Guru',
        description:'My experience with Shwassa Guru has been nothing short of miraculous. Suffering from chronic back pain for years, I had tried various treatments with little success. Under Shwassa Guru’s expert guidance, I have seen significant improvement in my condition. The tailored yoga practices have strengthened my back and improved my overall flexibility and posture. Beyond the physical benefits, the mental and emotional growth I have experienced is incredible.',
        written_by:'Priya Sharma',
        date:'Mar 15 2023'
    }
]

export const galleryArr = [
    {
        src: gallery1,
        width: 6,
        height: 4
    },
    {
        src: gallery2,
        width: 2,
        height: 2
    },
    {
        src: gallery3,
        width: 2,
        height: 2
    },
    {
        src: gallery4,
        width: 4,
        height: 2
    },
    {
        src: gallery5,
        width: 4,
        height: 2
    },
    {
        src: gallery6,
        width: 2,
        height: 2
    },
    {
        src: gallery7,
        width: 2,
        height: 2
    },
    {
        src: gallery8,
        width: 2,
        height: 2
    },
    {
        src: gallery9,
        width: 4,
        height: 4
    },
    {
        src: gallery10,
        width: 3,
        height: 2
    },
    {
        src: gallery11,
        width: 3,
        height: 2
    },
    {
        src: gallery12,
        width: 3,
        height: 2
    },
    {
        src: gallery13,
        width: 3,
        height: 2
    },
    {
        src: gallery14,
        width: 3,
        height: 2
    },
    {
        src: gallery15,
        width: 3,
        height: 2
    },
    {
        src: gallery16,
        width: 3,
        height: 2
    },
    {
        src: gallery17,
        width: 3,
        height: 2
    },
    {
        src: gallery18,
        width: 3,
        height: 2
    },
    {
        src: gallery19,
        width: 3,
        height: 2
    },
    {
        src: gallery20,
        width: 3,
        height: 2
    },

    {
        src: gallery21,
        width: 3,
        height: 2
    },
    {
        src: gallery22,
        width: 3,
        height: 2
    },

    {
        src: gallery23,
        width: 3,
        height: 2
    },
    {
        src: gallery24,
        width: 3,
        height: 2
    },
    {
        src: gallery25,
        width: 3,
        height: 2
    },
    {
        src: gallery26,
        width: 3,
        height: 2
    },
    {
        src: gallery27,
        width: 3,
        height: 2
    },
    {
        src: gallery28,
        width: 3,
        height: 2
    },
    {
        src: gallery29,
        width: 3,
        height: 2
    },
    {
        src: gallery30,
        width: 3,
        height: 2
    },
    {
        src: gallery31,
        width: 3,
        height: 2
    },
    {
        src: gallery32,
        width: 3,
        height: 2
    },
    {
        src: gallery33,
        width: 3,
        height: 2
    },
    {
        src: gallery34,
        width: 3,
        height: 2
    },

    {
        src: gallery35,
        width: 3,
        height: 2
    },
    {
        src: gallery36,
        width: 3,
        height: 2
    },
    {
        src: gallery37,
        width: 3,
        height: 2
    },
    {
        src: gallery38,
        width: 3,
        height: 2
    },
    {
        src: gallery39,
        width: 3,
        height: 2
    },
    {
        src: gallery40,
        width: 3,
        height: 2
    },
    {
        src: gallery41,
        width: 3,
        height: 2
    },
    {
        src: gallery42,
        width: 3,
        height: 2
    },
    {
        src: gallery43,
        width: 3,
        height: 2
    },
    {
        src: gallery44,
        width: 3,
        height: 2
    },
    {
        src: gallery45,
        width: 3,
        height: 2
    },
    {
        src: gallery46,
        width: 3,
        height: 2
    },
    {
        src: gallery39,
        width: 3,
        height: 2
    },
    {
        src: gallery40,
        width: 3,
        height: 2
    },
    {
        src: gallery41,
        width: 3,
        height: 2
    },
    {
        src: gallery42,
        width: 3,
        height: 2
    },
    {
        src: gallery43,
        width: 3,
        height: 2
    },
    {
        src: gallery44,
        width: 3,
        height: 2
    },
    {
        src: gallery45,
        width: 3,
        height: 2
    },
    {
        src: gallery46,
        width: 3,
        height: 2
    },
    {
        src: gallery47,
        width: 3,
        height: 2
    },
    {
        src: gallery48,
        width: 3,
        height: 2
    },
    {
        src: gallery49,
        width: 3,
        height: 2
    },
    {
        src: gallery50,
        width: 3,
        height: 2
    },
    {
        src: gallery51,
        width: 3,
        height: 2
    },
    {
        src: gallery52,
        width: 3,
        height: 2
    },
    {
        src: gallery53,
        width: 3,
        height: 2
    },
    {
        src: gallery54,
        width: 3,
        height: 2
    },
    {
        src: gallery55,
        width: 3,
        height: 2
    },
    {
        src: gallery56,
        width: 3,
        height: 2
    },
    {
        src: gallery57,
        width: 3,
        height: 2
    },
    {
        src: gallery58,
        width: 3,
        height: 2
    },
]

export const mediaArr = [
    {
        img:media_release1,
        text:'',
    },
    {
        img:media_release2,
        text:'',
    },
    {
        img:media_release3,
        text:'',
    },
    {
        img:media_release4,
        text:'',
    },
    {
        img:media_release5,
        text:'',
    },
    {
        img:media_release6,
        text:'',
    },
    {
        img:media_release7,
        text:'',
    },
    {
        img:media_release8,
        text:'',
    },
    {
        img:media_release9,
        text:'',
    },
    {
        img:media_release10,
        text:'',
    },
    {
        img:media_release11,
        text:'',
    },
    {
        img:media_release12,
        text:'',
    },
    {
        img:media_release13,
        text:'',
    },
    {
        img:media_release14,
        text:'',
    },
    {
        img:media_release15,
        text:'',
    },
    {
        img:media_release16,
        text:'',
    },
    {
        img:media_release17,
        text:'',
    },
    {
        img:media_release18,
        text:'',
    },
    {
        img:media_release19,
        text:'',
    },
    {
        img:media_release20,
        text:'',
    },
    {
        img:media_release21,
        text:'',
    },
    {
        img:media_release22,
        text:'',
    },
    {
        img:media_release23,
        text:'',
    },
    {
        img:media_release24,
        text:'',
    },
    {
        img:media_release25,
        text:'',
    },
    {
        img:media_release26,
        text:'',
    },
    {
        img:media_release27,
        text:'',
    },
    {
        img:media_release28,
        text:'',
    },
    {
        img:media_release29,
        text:'',
    },
    {
        img:media_release30,
        text:'',
    },
    {
        img:media_release31,
        text:'',
    },
    {
        img:media_release32,
        text:'',
    },
    {
        img:media_release33,
        text:'',
    },
    {
        img:media_release34,
        text:'',
    },
    {
        img:media_release35,
        text:'',
    },
    {
        img:media_release36,
        text:'',
    },
    {
        img:media_release37,
        text:'',
    },
    {
        img:media_release38,
        text:'',
    },
    {
        img:media_release39,
        text:'',
    },
    {
        img:media_release40,
        text:'',
    },
    {
        img:media_release41,
        text:'',
    },
    {
        img:media_release42,
        text:'',
    },
    {
        img:media_release43,
        text:'',
    },
    {
        img:media_release44,
        text:'',
    },
    {
        img:media_release45,
        text:'',
    },
]

export const eventsArr = [
    {
        img:event1,
        title:'10th international Day of YOGA',
        description:'Yoga for Self & Society Join us on the mentioned location at mentioned time',
        location:'JSW Township, Vidyanagar, Torangallu, Bellary District',
        TYPE:'Upcoming',
        content:`

        `,
        date:'Jun 21 2024 6 Am'
    },
    {   
        id:2,
        img:event2,
        title:'ಹರಜಾತ್ರಾ ಮಹೋತ್ಸವ -Hara Jatra Mahotsav ',
        description:'ಹರಜಾತ್ರಾ ಮಹೋತ್ಸವಕ್ಕೆ ಹೃದಯ ಪೂರ್ವಕ ಸುಸ್ವಾಗತ.',
        location:'Panchamasali Jagadguru Peetha',
        content:``,
        date:'Jan 14/15 2024'
    },
    {   
        id:3,
        img:event3,
        title:'Hatha Yoga & Meditation Retreat, San Jose',
        description:'Combine the classical form of Hatha Yoga with the contemporary lifestyles of California this summer!',
        location:'Balaji Temple,5004 N.1st Street, PO Box-1146, San Jose (Alviso),CA 95002',
        content:``,
        duration:`2 Hr`,
        date:'Jan 13/14 2019'
    },
    {   
        id:4,
        img:event4,
        title:'Hatha Yoga Classes : Free Session Program',
        description:'Hatha means ‘force’ and Yoga means ‘union’, this summer remove the blockages and channelize the energy flow by opening your chakras with the mystical powers of Yoga.',
        location:'Suburban Collection Showplace, Novi, MI 46100 Grand River, Novi, MI, 48374 Detroit',
        content:``,
        duration:`1 Hr`,
        date:'Jul 5/6/7 2019'
    },
    {   
        id:5,
        img:event5,
        title:'Kung Fu Yoga Retreat',
        description:'Kung Fu Yoga Training with Le Yogi Coudoux https://youtu.be/2rXXtnwqz_Q *Kung Fu Yoga *gTum-mo Method *Yogic Breath *Mindful Meditation *Yoga Nidra',
        location:'Shwaasa Yoga Center,Bengaluru.',
        content:``,
        duration:`3 days`,
        date:'Jun 15/16/17 2018'
    },
    {   
        id:6,
        img:event6,
        title:'Himalayan Yoga Meditation Retreat',
        description:'Travel into the lap of Himalayas for an experience that goes beyond nature walks and sight-seeing expeditions. Journey to the land of yoga and immerse in 5-Days of majestic serenity with us, by the bank of holy Ganges. Our aim is to help you create the most heartwarming memories and to lead you towards the reunion of your body with the mind and soul.',
        location:'Suburban Collection Showplace, Novi, MI 46100 Grand River, Novi, MI, 48374 Detroit',
        content:`
           <p id="font_normal_with_space">Experience Purity And Inner Bliss This Fall floral-decor Indulge in a soulful escapade at the spiritual core of the universe on a Himalayan Yoga and Meditation Retreat in India.</p>

           <p id="font_normal_with_space">Sink into the purity emanating beauty of the Himalayas.</p>

           <p id="font_normal_with_space">Intensify your yoga and meditation practices under the guidance of our revered guru.</p>

           <p id="font_normal_with_space">Form a soul-stirring medley with the resonating presence of the holy Ganges.</p>
           
           <p id="font_normal_with_space">Purify and cleanse all those mental, physical and spiritual blockages during this wonderful retreat.</p>

           <p id="font_normal_with_space">Catch a breathtaking glimpse of the true yogic heritage at India’s most sacred towns.</p>

           <p id="font_normal_with_space">A priceless opportunity to enhance and further your yoga and meditation practice to an esteemed and venerated level with our Yoga and Meditation Retreat in India. Take in the sacred sight and history of ancient temples, traditional fests, and rituals. Meditate in some of the most ancient caves where several luminaries dwelled for years and attained enlightenment.</p>
           
           <p id="font_normal_with_space">Interact and bond with sages, gurus, priests of the science, and get an opportunity to seek wisdom from them. Relish mouth-watering traditional flavors of India; reside in warm, clean and cozy accommodation.</p>
         
           <p id="font_normal_with_space">Go for optional river rafting on the thrilling rapids of Ganga. Get introduced to the science and the discipline of yoga and meditation like never before, completely authentic, traditional and one of a kind with us.</p>
          
        `,
        duration:`1 Hr`,
        date:'Jul 7/6/5 2019'
    },
    {   
        id:7,
        img:event7,
        title:'Hatha Yoga Classes : Free Session Program',
        description:'Hatha means ‘force’ and Yoga means ‘union’, this summer remove the blockages and channelize the energy flow by opening your chakras with the mystical powers of Yoga.',
        location:'Shwaasa Yoga Center,Bengaluru.',
        content:``,
        duration:`2 days`,
        date:'Jul 7/6/5 2019'
    },
    {   
        id:8,
        img:event8,
        title:'Babaji State Meditation Retreat with Victor Truviano in Bengaluru',
        description:'Babaji State Meditation Retreat in Bengaluru with Yogi Victor Truviano. The Babaji State is an encounter of yogis for yogis. In order to remember ourselves in the first meditator that from the mind projects in us the yoga for the body. Yoga begins and ends in the existing thought, all the flexibility that exists in it exists also in the body, all the goodness that exists in the mind exists in the body, all calmness, attention and love that lies here generates consequences for the body and its movements, as do all the opposites too',
        location:'Suburban Collection Showplace, Novi, MI 46100 Grand River, Novi, MI, 48374 Detroit',
        content:``,
        duration:`2 days`,
        date:'Apr 19/21 2018'
    },
    {   
        id:9,
        img:event9,
        title:'Babaji State Retreat with Victor Truviano',
        description:'The Babaji State is an encounter of yogis for yogis. In order to remember ourselves in the first meditator that from the mind projects in us the yoga for the body. Yoga begins and ends in the existing thought, all the flexibility that exists in it exists also in the body, all the goodness that exists in the mind exists in the body, all calmness, attention and love that lies here generates consequences for the body and its movements, as do all the opposites too.',
        location:'Ashirwad Kunj,Jonk Village, Swaragashram,Ram Jhulla, Rishikesh, Uttarakhand 249304.',
        content:``,
        duration:`3 days`,
        date:'Apr 19/21 2018'
    },
    {   
        id:10,
        img:event10,
        title:'Yoga Meditation Retreat in Himalayas',
        description:'Meditate on lap of mighty Himalayas. http://shwaasa.org/himalayan-yoga-meditation-retreat/ Experience Purity And Inner Bliss This Fall floral-decor Indulge in a soulful escapade at the spiritual core of the universe on a Himalayan Yoga and Meditation Retreat in India.',
        location:'Chopta Village,',
        content:`

           <p id="font_normal_with_space">Sink into the purity emanating beauty of the Himalayas.</p>

           <p id="font_normal_with_space">Intensify your yoga and meditation practices under the guidance of our revered guru.</p>

           <p id="font_normal_with_space">Form a soul-stirring medley with the resonating presence of the holy Ganges.</p>
           
           <p id="font_normal_with_space">Purify and cleanse all those mental, physical and spiritual blockages during this wonderful retreat.</p>

           <p id="font_normal_with_space">Catch a breathtaking glimpse of the true yogic heritage at India’s most sacred towns.</p>

           <p id="font_normal_with_space">A priceless opportunity to enhance and further your yoga and meditation practice to an esteemed and venerated level with our Yoga and Meditation Retreat in India. Take in the sacred sight and history of ancient temples, traditional fests, and rituals. Meditate in some of the most ancient caves where several luminaries dwelled for years and attained enlightenment.</p>
           
           <p id="font_normal_with_space">Interact and bond with sages, gurus, priests of the science, and get an opportunity to seek wisdom from them. Relish mouth-watering traditional flavors of India; reside in warm, clean and cozy accommodation.</p>
         
           <p id="font_normal_with_space">Go for optional river rafting on the thrilling rapids of Ganga. Get introduced to the science and the discipline of yoga and meditation like never before, completely authentic, traditional and one of a kind with us.</p>
          
           `,
        duration:`3 days`,
        date:'Mar 08/12 2018'
    },
    {   
        id:11,
        img:event11,
        title:'Yoga Meditation Retreat in the Himalayas',
        description:'Travel into the lap of Himalayas for an experience that goes beyond nature walks and sight-seeing expeditions. Journey to the land of yoga and immerse in 5-Days of majestic serenity with us, by the bank of holy Ganges and up towards the Himalayan mountains.Our aim is to help you create the most heartwarming memories and to lead you towards the reunion of your body with the mind and beyond.',
        location:'Trek to Deoria Tal',
        content:`

           `,
        duration:`5 days`,
        date:'Mar 08/12 2018'
    },
    {   
        id:12,
        img:event12,
        title:'Himalayan Yoga & Meditation Retreat',
        description:'Travel into the lap of Himalayas for an experience that goes beyond nature walks and sight-seeing expeditions. Journey to the land of yoga and immerse in 5-Days of majestic serenity with us, by the bank of holy Ganges and up towards the Himalayan mountains.Our aim is to help you create the most heartwarming memories and to lead you towards the reunion of your body with the mind and beyond.',
        location:'Trek to Deoria Tal',
        content:`
           
           <p id="font_normal_with_space">Experience Purity And Inner Bliss This Fall floral-decor</p>

           <p id="font_normal_with_space">Indulge in a soulful escapade at the spiritual core of the universe on a Himalayan Yoga and Meditation Retreat in India.</p>
           
           <p id="font_normal_with_space">Sink into the purity emanating beauty of the Himalayas. Intensify your yoga and meditation practices under the guidance of our revered guru.</p>
         
           <p id="font_normal_with_space">Form a soul-stirring medley with the resonating presence of the holy Ganges. Purify and cleanse all those mental, physical and spiritual blockages during this wonderful retreat.</p>
          
           <p id="font_normal_with_space">Catch a breathtaking glimpse of the true yogic heritage at India’s most sacred towns.</p>
          
           <p id="font_normal_with_space">A priceless opportunity to enhance and further your yoga and meditation practice to an esteemed and venerated level with our Yoga and Meditation Retreat in India. Take in the sacred sight and history of ancient temples, traditional fests, and rituals. Meditate in some of the most ancient caves where several luminaries dwelled for years and attained enlightenment.</p>

           <p id="font_normal_with_space">Interact and bond with sages, gurus, priests of the science, and get an opportunity to seek wisdom from them. Relish mouth-watering traditional flavors of India; reside in warm, clean and cozy accommodation.</p>
           
           <p id="font_normal_with_space">Go for optional river rafting on the thrilling rapids of Ganga. Get introduced to the science and the discipline of yoga and meditation like never before, completely authentic, traditional and one of a kind with us.</p>

           `,
        duration:`5 days`,
        date:'Mar 08/12 2018'
    },
    {   
        id:13,
        img:event13,
        title:'Meditation Retreat in the Himalayas.',
        description:'Travel into the lap of Himalayas for an experience that goes beyond nature walks and sight-seeing expeditions. Journey to the land of yoga and immerse in 5-Days of majestic serenity with us, by the bank of holy Ganges and up towards the Himalayan mountains.Our aim is to help you create the most heartwarming memories and to lead you towards the reunion of your body with the mind and beyond.',
        location:'Chopta Chandrashila Trek',
        content:`
         
           `,
        duration:`5 days`,
        date:'Mar 08/12 2018'
    },
    {   
        id:14,
        img:event14,
        title:'Sharan Sanskriti Utsav-2018',
        description:'61st Maha Samadhi Day Celebrations Of Jagadguru Sri Jayadev Murugharajendra Mahaswamiji & Sharana Sanskriti Utsav-2018.',
        location:'Shiva Yoga Ashram,Davanageri',
        content:`
         
           `,
        duration:`1 hr 30 min`,
        date:'Mar 02 2018'
    },
    {   
        id:15,
        img:event15,
        title:'Maha Shivaratri Celebrations',
        description:'Every year, Maha Shivaratri is celebrated by those who follow Hinduism.',
        location:'Gachchina Mutt,Athani',
        content:`

           <p id="font_normal_with_space">In every luni-solar month of the Hindu calendar, there is a Shivratri on the 13th night and 14th day, but the biggest celebration of the Hindu god Shiva comes once a year, in late winter – either in February or March – and before spring arrives.</p>

           <p id="font_normal_with_space">Maha Shivaratri means ‘the great night of Shiva’, and will be celebrated this year on 13th February – but what is the festival all about and how do people mark the occasion?</p>
           
           <p id="font_normal_with_space">What is the story behind it?</p>
         
           <p id="font_normal_with_space">According to the most popular legend, during the great mythical churning of the ocean – known as Samudra Manthan, and conducted by gods and demons so that nectar could be obtained to make them immortal – a pot of poison emerged.</p>
          
           <p id="font_normal_with_space">This poison was so potent that nobody was prepared to even touch it – it had the potential to burn the whole world.</p>
           <p id="font_normal_with_space">The only one who could get rid of the poison was Lord Shiva, who agreed to consume it.</p>
           <p id="font_normal_with_space">The poison was so deadly, that if any had entered Lord Shiva’s stomach – which represents the universe – the world would have been destroyed.</p>
           <p id="font_normal_with_space">He held it in his throat, which then turned dark blue as the poison took effect, and became known as Neelkanth.</p>
           <p id="font_normal_with_space">Maha Shivaratri is a day where Hindus acknowledge their thanks to Lord Shiva for protecting the wHow do people honour the festival? In India, major temples are attended during Maha Shivaratri, and the Mandi fayre is a particularly famous celebration, held for seven days.</p>
           <p id="font_normal_with_space">There are many Shiva followers in central India, with the festival celebrated enthusiastically across the country.</p>
           <p id="font_normal_with_space">In Nepal, there are various music and dance performances throughout the night, and holy rituals are performed.</p>
           <p id="font_normal_with_space">Married women pray for their husbands’ well-being, and unmarried women pray they will have a husband like Shiva – who is seen as the ideal.</p>
           <p id="font_normal_with_space">In Indo-Caribbean communities, Hindus spend the night in temple, offering jhalls (an offering of milk and curd, flowers, sugarcane and sweets) to Lord Shiva.</p>
           <p id="font_normal_with_space">What is eaten at the celebration?</p>
           <p id="font_normal_with_space">It is traditional to fast during Maha Shivaratri, and many eat simple foods that are easily digested, such as milk, yogurt, fruit, almonds, peanuts, cashews and honey.</p>
           <p id="font_normal_with_space">Some people go on a diet of fruits and milk, while others don’t take in anything for the entire festival.</p>
          
         
           `,
        duration:`12 hr`,
        date:'Feb 13/14 2018'
    },
    {   
        id:16,
        img:event16,
        title:'Shwaasa Guru',
        description:'Yoga Science Camp on 11,12,13th February 2018 in Sri Durdundeshwar Samsthan Mutt,Nidasoshi.Belagavi District.',
        location:'Shiva Yoga Ashram,Davanageri',
        content:`
         
           `,
        duration:`3 days`,
        date:'Mar 02 2018'
    },
    {   
        id:17,
        img:event17,
        title:'Yoga Retreat By Tao Porchon Lynch',
        description:'Yoga Retreat is an inspirational event that helps you to master on Health,strength and happiness.',
        location:'Shangri-La Bengaluru',
        content:`
         
           `,
        duration:`2 days`,
        date:'Jun 22/25 2018'
    },
    {   
        id:18,
        img:event18,
        title:'International Yoga Day',
        description:"Meet & Learn Yoga by World's oldest Yoga Master Tao Porchon Lynch and Amma Nanammal",
        location:'Sree Kanteerava Stadium',
        content:`
         
           `,
        duration:`2 Hr`,
        date:'Jun 21 2017'
    },
    {   
        id:19,
        img:event19,
        title:'International Yoga Day',
        description:"Shwaasa presents International Day Of Yoga in association with Government of Karnataka on 21st June at 7.00 AM in Sree Kanteerava Stadium, Bengaluru.",
        location:'Sree Kanteerava Stadium',
        content:`
         
           `,
        duration:` 1 hr 30 min`,
        date:'Jun 21 2017'
    },
    {   
        id:20,
        img:event20,
        title:'International Day of Yoga',
        description:"On account of ‘International Day of Yoga ' on 21st June 2015, on Sunday.we SHWAASA Trust & Karnataka Olympic Association in association with TV9 – Karnataka have initiated this mega event “International Day of Yoga” at Sri Kanteerava Stadium.",
        location:'Sree Kanteerava Stadium',
        content:`
         
           <p id="font_normal_with_space">Shwaasaguru Sri Vachananand Swamiji is a spiritual leader from Karnataka, known for his contributions in iyengar yoga . He is best known for power yoga classes in bangalore among health conscious people of Karnataka through his mass yoga camps and TV shows.</p>

           <p id="font_normal_with_space">This Mega event will begin with Yogathon from Vidhana Soudha at morning 07:30 am and conclude at Sri Kanteerava Outdoor Stadium.</p>
           
           <p id="font_normal_with_space">International Day of Yoga will see active gathering over 18000 yoga followers, admirers and the yoga community at Sri Kanteerava Outdoor Stadium this mega event/edition will unite this yoga community and given them an opportunity to learn best yoga techniques & Unique practises from our renown yoga gurus.</p>
         
           <p id="font_normal_with_space">Yoga championship will register close to 3000 participants</p>
          
           <p id="font_normal_with_space">Yoga Fitness Award Night a mega felicitation event to the renowned Yoga Fitness Achievers in many fields - ie! common man to the businesses, Bureaucrats, Film fraternities, Social Servitudes’ etc...,</p>
          
           <p id="font_normal_with_space">International Day of Yoga event will see a active participation of over 18000 yoga followers, admirers and yoga community and giving them an opportunity to learn & adopt the ancient yoga and health tradition in their daily life for better living.</p>
          
           `,
        duration:``,
        date:'Jun 21 2015'
    },
]

export const blogsArr = [
    {
        image:post1,
        id:1,
        title:'Diverse Yoga Offerings',
        description:'Embark on a transformative journey with our diverse yoga programs, tailored to enhance your well-being. From the immersive 200-hour Yoga Teacher Training to rejuvenating Yoga Retreats and soul-soothing Meditation Retreats, each offering is designed to bring peace and joy into your life.',
        date:"03-23-2023"
    },
    {
        image:post2,
        id:2,
        title:'Yoga Beyond Boundaries',
        description:'Shwaasa Yoga Ashram extends its services to Nepal and other yogic destinations in India, including serene locations like Kerala and the spiritual haven of Rishikesh. Wherever you are, we bring the essence of authentic yoga to your doorstep.',
        date:"05-15-2024"

    },
    {
        image:post3,
        id:3,
        title:'Discover Your True Calling:',
        description:"Our Yoga Ashram in India provides a sacred space not only for delving into ancient wisdom but also for quieting the mind to hear your 'true calling.' It's more than a place of learning; it's a sanctuary for self-discovery.",
        date:"01-01-2023"
    }
]