import React from 'react'
import { logo, } from '../components/constant/Appconstants'
import banner0 from '../components/images/banner/banner0.jpg'


function Splash() {
  return (
    <div className='w-screen relative flex items-center justify-center h-screen overflow-hidden'>

        <img src={logo} />

        {/* <img className='w-[40%] h-[40%] object-contain' src={banner0} />

          <h6 className='font_medium absolute left-[5%] text-[30px] md:text-[60px] text-black'>SHWAASA</h6>
          <h6 className='font_medium absolute right-[15%] text-[30px] md:text-[60px] text-black'>GURU</h6> */}
    </div>
  )
}

export default Splash