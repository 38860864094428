import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { IoIosArrowRoundBack } from "react-icons/io";
import DOMPurify from "dompurify";

function EventsDetail() {

  const navigator = useNavigate()
  const {state} = useLocation()

  return (
    <div>
       <div className='flex bg-[#F47928]/20 px-[5%] py-[14px]'>
        <div className='flex items-center cursor-pointer' onClick={()=>navigator(-1)}>
        <IoIosArrowRoundBack color='#F47928' />
        <h6 className='text-[11px] ml-2 text-[#F47928] font_bold'>Back to Events</h6>
        </div>
      </div>
     
         {/* <img src={state?.banner} className={`w-screen  h-[90vh] ${(state?.image_type !== undefined && state?.image_type !== null)? 'object-contain' : 'object-cover'}`} />

         <div className='w-[90%] md:w-[80%] mx-auto my-[5%] mb-[10%]'>
            <h6 className='font_bold text-[25px]'>{state?.company}</h6>
            {(state?.service !== '' && state?.service !== undefined) && <h6 className='font_normal text-[14px] mb-4'>{state?.service}</h6>}
            {(state?.project_name !== '' && state?.project_name !== undefined) && <h6 className='font_normal text-[14px] mb-4'>PROJECT NAME : <span className='font_bold'>{state?.project_name}</span> </h6>}
            {(state?.total_sqft !== '' && state?.total_sqft !== undefined) && <h6 className='font_normal text-[14px] mb-4'>TOTAL SFT : <span className='font_bold'>{state?.total_sqft}</span> </h6>}
            {(state?.nature !== '' && state?.nature !== undefined) && <h6 className='font_normal text-[14px] mb-4'>NATURE OF THE PROJECT : <span className='font_bold'>{state?.nature}</span> </h6>}
            {(state?.scope !== '' && state?.scope !== undefined) && <h6 className='font_normal text-[14px] mb-10'>SCOPE : {state?.scope}</h6>}

            {(state?.video !== '' && state?.video !== null && state?.video !== undefined) &&
            <video className='w-full' src={state?.video} controls />}
         </div> */}

         <img src={`${state?.img}`} className={`w-screen  md:h-[90vh] ${(state?.image_type !== undefined && state?.image_type !== null && state?.image_type !== '')? 'object-contain' : 'object-contain'}`} />

         <div className='w-[90%] md:w-[80%] mx-auto my-[5%] mb-[10%]'>
            {(state?.title !== '' && state?.title !== undefined) && <h6 className='font_normal text-[18px] mb-4'><span className='font_medium'>{state?.title}</span> </h6>}
            {(state?.location !== '' && state?.location !== undefined) && <h6 className='font_normal text-[14px] mb-4'>Location : <span className='font_medium'>{state?.location}</span> </h6>}
            {(state?.date !== '' && state?.date !== undefined) && <h6 className='font_normal text-[14px] mb-4'>Date : <span className='font_medium'>{state?.date}</span> </h6>}
            {(state?.description !== '' && state?.description !== undefined) && <h6 className='font_normal text-[14px] mb-4'><span className='font_bold'>{state?.description}</span> </h6>}

            <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(state?.content)}}>
            </div>
           
           
         </div>

    </div>

  )
}

export default EventsDetail  