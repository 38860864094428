import React from 'react'
import { useNavigate } from 'react-router-dom'

function NotFound() {

  const navigator = useNavigate()   
  return (
    <div className="w-full relative z-0 flex items-center justify-center py-[5%]">


        <div className='flex flex-col items-center justify-center'>
            <h6 className='text-[50px] text-center text-[#F47928] md:text-[124px] font_medium'>404</h6>
            <h6 className='text-[17px] text-center font_normal w-[80%] mx-auto text-black/50'>The page you're  looking for is not found please check whether the entered link is valid or not just go back to your previous page.</h6>
        
            <h6 data-aos="fade-up" data-aos-delay="300" onClick={()=>navigator(-1)} className="border font_normal border-slate-400 p-1.5 text-[11px] cursor-pointer transition-all duration-300 hover:scale-105 hover:border-[#F47928] hover:bg-[#F47928] hover:text-white  w-[90px] text-center mt-5">Go Back</h6>
        
        </div>
    </div>
  )
}

export default NotFound