import React from 'react'
import { useNavigate } from 'react-router-dom'
import about1 from '../components/images/about1.jpg'
import { GiSunflower } from "react-icons/gi";

import vission from '../components/images/vision_mission_values/vision.jpg'
import mission from '../components/images/vision_mission_values/mission.jpg'
import values from '../components/images/vision_mission_values/values.jpg'

import award from '../components/images/award.png'

function About() {

  const navigator = useNavigate()


  return (
    <div className="w-full relative z-0">
       <div className='relative flex items-center justify-center bg-about_bg bg-top bg-cover h-[200px]  md:h-[340px]'>
        <div className='z-10'>
          <h6 className='text-[30px] text-white text-center tracking-[10px] font_thicker opacity-80'>ABOUT US </h6>
          <h6 className='text-center font_normal text-white opacity-100 text-[14px]'> <span onClick={()=>navigator('/')} className='relative cursor-pointer after:bg-[#fff] after:absolute after:h-[2px] after:w-0 after:bottom-0 after:left-0  text-white opacity-100  hover:after:w-full after:transition-all after:duration-300'>Home</span> / About Us </h6>
          <h6 className='text-[13px] mt-1 w-[80%] mx-auto text-white text-center font_normal opacity-80'>Know more about shwaasa guru and about his vision nad mission. </h6>
        </div>
        <div className='bg-black opacity-60 absolute top-0 left-0 w-full h-[200px]  md:h-[340px]'>
        </div> 
      </div>


     
      <div className="w-[80%] md:w-[90%] md:flex mx-auto py-10">
          
          <img data-aos="fade-right" alt="logo" src={about1} className='w-full md:w-[35%] md:mx-[7.5%] h-[420px] object-cover' />

          <div data-aos="fade-up" className="mt-10 md:mt-0 md:w-[50%]">
                <h6 data-aos="fade-left" data-aos-delay="200" className="text-[20px] font_medium font-[800]">About </h6>
                
                <div data-aos="fade-left" className="md:w-[80%] mt-2">
                <h6 className="text-[14px] font_normal mt-2"><span className='font-[800]'>Swami Vachanananda Guru</span>, commonly known as <span className='font-[800]'>Shwaasa Guru</span>, is a yogi, social activist, <span className='font-[800]'>philanthropist</span> from Karnataka, India. He took sannyasa at a very tender age. He founded Shwaasa, a non-profit organization which offers yoga programs in India and around the world. The foundation conducts yoga camps and is involved in various social and community development activities creating health awareness among people irrespective of age, religion, language, and cast.</h6>  
                <h6 className="text-[14px] font_normal mt-2">A divine ambiance is what the Yoga Ashram in India creates to make impactful changes in everyone`&apos;`s life. Shwaasa, a spiritually-focused organization, encourages yogis to immerse themselves into a deep understanding of the ancient art. </h6>  

                <h6 className="text-[14px] font_normal mt-2">Built upon the foundations of authenticity, trust and devotion towards the ancient science, the Shwaasa Yoga Ashram was founded in 2012 by Swami Vachananand Ji in Bangalore, Karnataka.</h6>

          </div>
          </div>


         </div>

           {/* Vision   */}
           <div className='relative bg-[#fffaeb]'>
       
       <div className='text-center py-16 w-[80%]  md:w-[75%] mx-auto'>
        <h6 data-aos="fade-up" className='font-[800] font_medium text-[20px]'>Mission, Vision and Values of Shwaasa</h6>
             
          <div className='flex items-center justify-center mt-1'>
          <GiSunflower data-aos="fade-right" className='text-[#F47928]' />

          <h6 data-aos="fade-up" className='w-[40px] h-[2px] bg-[#F47928] text-center'></h6>
          <GiSunflower data-aos="fade-left" className='text-[#F47928]' />
          </div>

          <h6 data-aos="fade-up" className="font-[400] text-black font_normal text-[13px]">What we are looking for</h6>


         <div className='grid grid-cols-1 mt-10 md:grid-cols-3 gap-10 '>
          <div data-aos="fade-right" className='group relative overflow-hidden shadow-2xl  bg-[#f9f7f1]'>
            <div className='head-polygon w-max p-1 z-10 absolute right-0 top-0 px-4 bg-[#01334a] h-[30px]'>
              <h6 className='text-[12px] text-white font_medium font-[700]'>Vision</h6>
            </div>
            <div className='-z-10 h-[200px] overflow-hidden'>
            <img src={vission} className='group-hover:scale-105 w-screen duration-300 h-[200px] object-cover object-center' />
            </div>
            <div className='z-30 bg-[#f9f7f1] -mt-4'>
            <div className='z-10 clip-polygon-rl overflow-hidden -mt-[39px] bg-[#f9f7f1] h-[40px]'>
            </div>
            <h6 className="z-20 text-[14px] font_normal px-4 py-2 text-left">Shwaasa Yoga Ashram or ‘the doors of heaven’ is a recognized host which encounters the abilities and deep possibilities of a nonchalant yogi. The ambitious goals of our Indian Yoga Guru: Swami Vachananand Ji is not restricted to any geographical era or an ashram, his vision is to touch the lives of thousands of people just by using the power of ahimsa, co-existence and gratitude.</h6>
            </div>
          </div> 

          <div data-aos="fade-up" className='group relative shadow-2xl bg-[#f9f7f1]'>
            <div className='head-polygon w-max p-1 z-10 absolute right-0 top-0 px-4 bg-[#01334a] h-[30px]'>
              <h6 className='text-[12px] font_medium text-white font-[700]'>Mission</h6>
            </div>
            <div className='-z-10 h-[200px] overflow-hidden'>
            <img src={mission} className='group-hover:scale-105 w-screen duration-300 h-[200px] object-cover object-center' />
            </div>
            <div className='z-30 bg-[#f9f7f1] -mt-4'>
            <div className='z-10 clip-polygon-lr overflow-hidden -mt-[39px] bg-[#f9f7f1] h-[40px]'>
            </div>
            <h6 className="z-20 text-[14px] font_normal px-4 py-2 text-left">With a strong mission to make this world a single-roof home of love, happiness and peace for all the growing petals, evolving beings, and endangered animals is the aim of Shwaasa Yoga School. The art of living is not just about changing thyself but also the surroundings by letting your aura flouris every day with Yoga.</h6>
            </div>
          </div> 

          <div data-aos="fade-left" className='group relative shadow-2xl bg-[#f9f7f1]'>
            <div className='head-polygon w-max p-1 z-10 absolute right-0 top-0 px-4 bg-[#01334a] h-[30px]'>
              <h6 className='text-[12px] font_medium text-white font-[700]'>Values</h6>
            </div>
            <div className='-z-10 h-[200px] overflow-hidden'>
            <img src={values} className='group-hover:scale-105 w-screen duration-300 h-[200px] object-cover object-center' />
            </div>
            <div className='z-30 bg-[#f9f7f1] -mt-4'>
            <div className='z-10 clip-polygon-rl overflow-hidden -mt-[39px] bg-[#f9f7f1] h-[40px]'>
            </div>
            <h6 className="z-20 text-[14px] px-4 py-2 font_normal text-left">On an endnote, values are the key feature of all our Yoga Retreats or Yoga Teacher Training in India programs, which makes or breaks the strongest of foundations! 200 hours Yoga Teacher Training by Shwaasa revives the original beauty of the essence of Yoga with its supreme guidance and diligence to keep alive the timeless wisdom of Vedic tradition.</h6>
            </div>
          </div> 
         </div>
     
       </div>
           </div> 


        <div className='py-[5%] bg-white md:flex items-center px-[10%]'>

        <div data-aos="fade-right" className='md:w-[30%]'>
            <img src={award} />
          </div>
        
          <div className='md:w-[70%]'>
              <h6 data-aos="fade-left" data-aos-delay="200" className="text-[20px] font_medium font-[800]">YOGA RATNA </h6>
              <h6 data-aos="fade-left" data-aos-delay="200" className="text-[20px] text-gray-500 font_normal font-[800]">The <span data-aos="fade-left" data-aos-delay="200" className="text-[20px] text-[#F47928] font_medium font-[800]">ULTIMATE RECOGNITION</span> of the <span data-aos="fade-left" data-aos-delay="200" className="text-[20px] text-[#F47928] font_medium font-[800]">Yoga Sadhakas</span></h6>

              <h6  data-aos="fade-up" className="text-[14px] font_normal mt-2">The heritage of Yoga is rich with various yoga schools, yoga styles and great yoga masters. The treasure of yoga is still accessible to us only because of the relentless efforts of such great teachers and schools of Yoga. Many of their names and efforts were left unnoticed or unrecognized. Their contributions to the world are mostly forgotten.</h6>
              <h6  data-aos="fade-up" className="text-[14px] font_normal mt-2">Meanwhile, Yoga has caught the global eye and is getting popular by the day. The United Nations declared June 21st as International Yoga Day and it is being celebrated globally with much pomp and glory. </h6>
              <h6  data-aos="fade-up" className="text-[14px] font_normal mt-2">Shwaasa Yoga Institute has been committed to spreading yoga awareness across the world for the last decade. Shwaasa Guru Swami Vachanananda travelled to several countries and taught yoga to millions of followers over thousands of yoga training hours.</h6>
          </div>

          

        </div>   

    </div>
  )
}

export default About