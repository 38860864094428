import React,{useState} from 'react'
import { FaMapLocationDot } from "react-icons/fa6";
import { IoMdCall } from "react-icons/io";
import { AiOutlineMail } from "react-icons/ai";
import { useNavigate } from 'react-router-dom';
import ErrorComponent from '../components/constant/ErrorComponent';
import axios from 'axios';
import { message } from 'antd';

function Contact() {

  const [data,setdata] = useState({name:'',mobile:'',email:'',message:''})
  const [error,seterror] = useState({name:'',mobile:'',email:'',message:''})

  const navigator = useNavigate()


  function handleChange(e){
    setdata({...data,[e.target.name]:e.target.value})
    seterror({...error,[e.target.name]:''})
  }

  async function submitForm(){
    if(!data.name){
      seterror({...error,name:'The Full Name field is required*'})
    }else if(!data.mobile){
      seterror({...error,mobile:'The Mobile field is required*'})
    }else if(!data.email){
      seterror({...error,email:'The Email field is required*'})
    }else if(!data.message){
      seterror({...error,message:'The Message field is required*'})
    }else{

      let sendData = {
        ...data,
        phone:data.mobile,
        requirment:data.message,
        department_website:"Shwassa Guruji"
      }

     let response =  await axios.post(`https://fidelitustech.com/api/clients/create`,sendData)
     if(response?.status === 201){
        message.success("Form Submitted Successfully")
        setdata({name:'',mobile:'',email:'',message:''})
        seterror({name:'',mobile:'',email:'',message:''})
     }

    }
  }

  return (
    <div className="w-full relative z-0">
    <div className='relative flex items-center justify-center bg-contact_bg bg-bottom bg-cover h-[200px]  md:h-[340px]'>
      <div className='z-10'>
        <h6 className='text-[30px] text-white text-center tracking-[10px] font_thicker opacity-80'>Contact Us</h6>
        <h6 className='text-center font_normal text-white opacity-100 text-[14px]'> <span onClick={()=>navigator('/')} className='relative cursor-pointer after:bg-[#fff] after:absolute after:h-[2px] after:w-0 after:bottom-0 after:left-0  text-white opacity-100  hover:after:w-full after:transition-all after:duration-300'>Home</span> / Contact Us </h6>
        <h6 className='text-[13px] mt-1 w-[80%] mx-auto text-white text-center font_normal opacity-80'>To Contact Us please use any below procedure. </h6>
      </div>
      <div className='bg-black opacity-60 absolute top-0 left-0 w-full h-[200px]  md:h-[340px]'>
      </div>
    </div>




    <div className='w-[80%] my-20 md:flex items-start mx-auto'>
      <div className='md:w-[50%]'>

        <h1 data-aos="fade-right" className='text-[18px] font_medium font-[800]'>Contact us</h1>
        <div data-aos="fade-up" className='w-[80px] my-1 h-[5px] bg-[#F47928] rounded-full'></div>
        <h1 data-aos="fade-left" className='text-[13px] font_normal mt-2'>Reach out to us for any inquiry.</h1>

        <div data-aos="fade-up" className='mt-5'>
          <div>
            <input value={data?.name} name="name" onChange={handleChange} type="text" className='focus:outline-none w-full md:w-[90%] placeholder:text-[13px] border p-2' placeholder='Full name' />
          </div>
          <ErrorComponent error={error?.name} />

          <div>
            <input value={data?.mobile} name="mobile" onChange={handleChange} type="text" className='focus:outline-none mt-4 w-full md:w-[90%] placeholder:text-[13px] border p-2' placeholder='Your mobile' />
          </div>
          <ErrorComponent error={error?.mobile} />


          <div>
            <input value={data?.email} name="email" onChange={handleChange} type="email" className='focus:outline-none mt-4 w-full md:w-[90%] placeholder:text-[13px] border p-2' placeholder='Your email' />
          </div>
          <ErrorComponent error={error?.email} />


          <div>
            <textarea value={data?.message} name="message" onChange={handleChange} type="text" className='focus:outline-none mt-4 w-full md:w-[90%] placeholder:text-[13px] border p-2' placeholder='Message' />
          </div>
          <ErrorComponent error={error?.message} />


          <button onClick={submitForm} className='bg-[#F47928] w-full md:w-[90%] mt-5 p-2 text-[13px] font-[800] text-white'>SUBMIT</button>
        </div>
      </div>

      <div className='w-full mt-20 md:mt-0 relative md:w-[50%]'> 
            <div data-aos="fade-left" className="z-20">
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15547.744404599407!2d77.5797529!3d13.0397393!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae17ef2b7e93c9%3A0x39e72f9aa74768f7!2sShwaasa%20Yoga!5e0!3m2!1sen!2sin!4v1718712355115!5m2!1sen!2sin" className='w-full' height="400"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
            {/* bg-[#F47928] */}
            <div data-aos="fade-up" className='bg-circle1_bg w-[50%] md:w-[35%] -z-10 -top-10 absolute -right-[100px] h-[70%] bg-no-repeat'>
              
            </div>
      </div>
    
    
    </div>   



    <div className='grid my-16 mx-auto w-[80%] md:w-[70%] grid-cols-1 md:grid-cols-3'>
      <div data-aos="fade-right" className='md:border-r border-b md:border-b-0 py-4 flex flex-col items-center justify-center'>
        <FaMapLocationDot size={35} className='text-[#F47928]' />
        <div className='w-full flex flex-col items-center justify-center pb-3'>
          <h6 className='font-[800] font_normal text-[15px] py-1.5'>MEET US</h6>
          <h6 className='text-center font_normal text-[13px] px-4'>108F, MLA Layout, RMV 2nd Stage, Boopasandra, Near Boopasandra Bus-stand, Bangalore -560094, Karnataka, India.</h6>
        </div>  
      </div>

      <div data-aos="fade-up" data-aos-delay="300" className='md:border-r border-b md:border-b-0 py-4 flex flex-col items-center justify-center'>
        <div className=' flex items-center  justify-center'>
        <IoMdCall size={35} className='text-[#F47928]' />
        </div>
        <div className='w-full flex flex-col items-center justify-center pb-3'>
        <h6 className='font-[800] font_normal text-[15px] py-1.5'>CONTACT US</h6>
        <h6 className='text-center font_normal text-[13px] px-4'>+91 888-461-2008 / +91 888-432-2008</h6>
        </div>
      </div>

      <div data-aos="fade-left" className='flex flex-col py-4 items-center justify-center'>
        <div className='flex items-center  justify-center'>
        <AiOutlineMail size={35} className='text-[#F47928]' />
        </div>
        <div className='w-full flex flex-col items-center justify-center pb-3'>
        <h6 className='font-[800] font_normal text-[15px] py-1.5'>EMAIL ID</h6>
        <h6 className='text-center font_normal text-[13px] px-4'>info@shwaasa.org</h6>
        </div>
      </div>
    </div>   


    </div>
  )
}

export default Contact